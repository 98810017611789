<template>
    <div class="expense">
        <b-row class="header">
            <a class="navbar-brand ml-3">
                <img @click="back()" src="../../../assets/ic_navigate_back.png" class="y-icon-header">
            </a>
            <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">
                <h1 class="h5 text-center title">Give a Reason</h1>
            </div>
        </b-row>

        <section class="top h-100 w-100 d-flex justify-content-md-center align-items-center flex-column mb-5">
            <b-row class="mx-auto py-4 py-md-0 fill3">
                <b-col cols="2"></b-col>
                <b-col cols="8">
                    <div class="input-group input">
                        <input type="text" class="form-control border-0 py-4 " placeholder="Enter a Reason"
                               aria-label="Recipient's username" v-model="reason" aria-describedby="basic-addon2">
                    </div>
                </b-col>
                <b-col cols="2"></b-col>
            </b-row>

            <b-row class="mx-auto py-4 py-md-0 fill2">
                <b-col cols="2"></b-col>
                <b-col cols="8">
                    <button class="btn btn-danger button-text" @click="onNext">Next</button>
                </b-col>
                <b-col cols="2"></b-col>
            </b-row>

        </section>
    </div>
</template>

<script>
    import router from "../../../app/router/router";
    export default {
        name: "ExpenseReason",
        data(){
            return{
                expense: JSON.parse(localStorage.getItem("expense")),
                reason:null
            }
        },
        methods:{
            onNext(){
                if(this.reason != null){
                    this.expense.delete_reason = this.reason
                    localStorage.setItem("expense", JSON.stringify(this.expense))
                    router.push("/expense-confirm-delete")
                }
            },
            back(){
                window.history.back();
            },
        },

    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";

    .expense{
    }

    .header{
        padding: 10px;
        margin: 0;
        width: 100%;
        box-shadow: 0px 5px 3px #dedede;
    }

    .y-icon{
        width: 38px;
        height: 38px;
        margin-right: 5px;
        margin-left: 15px;
    }

    .y-icon-header{
        padding: 5px;
        width: 38px;
        height: 38px;
        margin: 10px;
    }

    .y-icon-header:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .title{
        color: #000000;
        font-size: 22px;
        font-weight: bold;
        margin-top: 15px;
    }

    .throw span{
        font-weight: bold;
    }

    .top{
        margin-top: 40px;
    }

    .fill{
        width: 100%;
        margin-top: 65px;
    }

    .fill2{
        width: 100%;
    }

    .fill3{
        width: 100%;
        margin-top: 95px;
    }

    .button-text{
        font-weight: bold;
        padding: 20px;
        width: 100%;
        font-size: 18px;
        margin-top: 35px;
    }

    .entry{
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .text{
        font-size: 18px;
        color: #4a4a4a;
    }

    .itext{
        font-size: 18px;
        color: #4a4a4a;
        font-style: italic;
    }

    .input{
        border: solid 1px #a0a0a0;
        border-radius: 6px;
    }

</style>