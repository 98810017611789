<template>
    <div class="calculator">
        <b-row class="h-100 w-100 m-0">
            <b-col cols="12">
                <b-row style="margin: 0; padding: 0;">
                    <b-col cols="12" style="margin: 0;">
                        <div class="input-group py-md-4 py-2">
                            <input type="text" class="form-control py-4 border-0" placeholder="Add a note"
                                   aria-label="Recipient's username" aria-describedby="basic-addon2" v-model="note">
                            <div class="input-group-append">
                                <span class="total" id="basic-addon2">UGX {{Number(total).toLocaleString()}}</span>
                            </div>
                        </div>
                    </b-col>
                </b-row>

                <b-row style="margin: 0; padding: 0;">
                    <div class="flex-grow-1">
                        <table class="border h-100 table-bordered table w-100 pb-3 fill">
                            <tr>
                                <td>
                                    <input @click="enter('1')" readonly type="text" value="1">
                                </td>
                                <td>
                                    <input @click="enter('2')" readonly type="text" value="2">
                                </td>
                                <td>
                                    <input @click="enter('3')" readonly type="text" value="3">
                                </td>
                                <td rowspan="2" class="button">
                                    <div>
                                        <img @click="del()" src="../../assets/ic_navigate_back.png" class="y-icon" alt="back">
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input @click="enter('4')" readonly type="text" value="4">
                                </td>
                                <td>
                                    <input @click="enter('5')" readonly type="text" value="5">
                                </td>
                                <td>
                                    <input @click="enter('6')" readonly type="text" value="6">
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <input @click="enter('7')" readonly type="text" value="7">
                                </td>
                                <td>
                                    <input @click="enter('8')" readonly type="text" value="8">
                                </td>
                                <td>
                                    <input @click="enter('9')" readonly type="text" value="9">
                                </td>
                                <td rowspan="2" class="button" @click="add">
                                    <div>
                                        <img src="../../assets/ic_add_qty.png" class="y-icon" alt="back">
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td colspan="2">
                                    <input @click="enter('00')" readonly type="text" value="00">
                                </td>
                                <td>
                                    <input @click="enter('0')" readonly type="text" value="0">
                                </td>
                            </tr>
                        </table>
                    </div>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: "Calculator",
        data(){
            return{
                total:"0",
                note:null
            }
        },
        methods:{
            enter(num){
                if(this.total == "0"){
                    this.total = num
                }else{
                    this.total += num
                }
            },
            del(){
                this.total = this.total.slice(0, -1)
                if(this.total.length == 0){
                    this.total = "0"
                }
            },
            add(){
                if(this.note != null && this.total != null){
                    let item = {
                        name: this.note,
                        price:this.total,
                        category:{name:"Custom Entry"},
                    }
                    var data = {
                        item:item,
                        qty:1,
                        team:[],
                        discounts:[],
                    }
                    var cart = JSON.parse(localStorage.getItem("cart"))
                    if(cart == null){
                        cart = [data]
                    }else{
                        if(cart.length > 0){
                            cart.push(data)
                        }
                    }
                    localStorage.setItem("cart", JSON.stringify(cart))
                    this.$root.$emit('refresh-cart')

                    this.note = null
                    this.total = "0"
                }
            }
        }
    }
</script>

<style scoped>
    @import "../../commons/css/main.css";

    .calculator{
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .y-icon{
        padding: 5px;
        width: 45px;
        margin: 10px;
    }

    .total{
        font-size: 32px;
        margin-left: 10px;
    }

    .button:hover{
        background: #f1f1f1;
    }

    .fill{
        margin-left: 10px;
        height: 100%;
    }

    td{
        height: 16vh;
    }


</style>