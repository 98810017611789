<template>
    <div class="txn-points">
        <b-row>
            <b-col class="header">
                <img src="../../../assets/ic_navigate_back.png" class="y-icon-header" alt="back" @click="back">
            </b-col>
        </b-row>

        <div class="d-flex justify-content-md-center align-items-center flex-column flex-grow-1 options">
            <h1 class="text-center h2 font-weight-bold">REDEEM</h1>
        </div>

        <div class="d-flex justify-content-md-center align-items-center flex-column flex-grow-1 options">
            <span class="text-center points">{{redeem.points}}</span>
            <span class="text-center text">Points</span>
        </div>

        <div class="d-flex justify-content-md-center align-items-center flex-column flex-grow-1 options">
            <p class="text-center text2">Make Every Day A Happy Day With A Free</p>
        </div>

        <div class="d-flex justify-content-md-center align-items-center flex-column flex-grow-1">
            <p class="text-center text2">{{redeem.name}}</p>
        </div>

        <b-row class="mx-auto py-4 py-md-0 fill2">
            <b-col cols="2"></b-col>
            <b-col cols="8">
                <button class="btn btn-danger button-text" @click="onRedeem()">Yes</button>
            </b-col>
            <b-col cols="2"></b-col>
        </b-row>
    </div>
</template>

<script>
    import router from "../../../app/router/router";

    export default {
        name: "RedeemedItem",
        data(){
            return{
                redeem: JSON.parse(localStorage.getItem("redeem"))
            }
        },
        methods:{
            back(){
                window.history.back()
            },
            onRedeem(){
                router.push("/redeem-library")
            },
        },
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";

    .header{
        padding: 15px;
    }

    .options{
        margin-top: 60px;
    }

    .y-icon-header{
        padding: 5px;
        width: 40px;
        margin-top: 10px;
        margin-left: 15px;
    }

    .points{
        font-weight: bold;
        color: #A6C34C;
        font-size: 60px;
    }

    .text{
        font-weight: bold;
        color: #A6C34C;
        font-size: 22px;
        line-height: 5px;
    }

    .text2{
        font-size: 22px;
    }

    .button-text{
        font-weight: bold;
        padding: 20px;
        width: 100%;
        font-size: 18px;
        margin-top: 35px;
    }

    .fill2{
        margin-top: 40px;
    }
</style>