<template>
    <div class="customer">
        <Header title="Customer"/>
        <b-row class="details">
            <section class="h-100 w-100 d-flex flex-column ">
                <div class="d-flex justify-content-md-center align-items-center flex-column flex-grow-1 px-3  pb-5 pb-md-0 ">
                    <b-col col md="10" lg="10" class="mx-auto">
                        <b-row>
                            <b-col col md="3" lg="4" class="text-center">
                                <div class="box-100">
                                    <img v-if="active" src="../../../assets/ic_active_user.png" class="badger"/>
                                    <img v-else src="../../../assets/non_active_user.png" class="badger"/>
                                </div>
                                <div class="user-points">
                                    <p class="h1" v-if="data.details != null">{{data.details.current_points}}</p>
                                    <p class="h1" v-else>0</p>
                                    <p class="h4">Points</p>
                                </div>
                            </b-col>

                            <b-col col md="9" lg="8" class="user-details">
                                <b-row>
                                    <b-col col md="7" lg="8">
                                        <b-row>
                                            <h1 class="h3 font-weight-bold">{{data.name}}</h1>
                                        </b-row>
                                        <b-row class="y-border-bottom y-border-top border-sm-light my-4 py-3">
                                            <b-col cols="12">
                                                <b-row>
                                                    <h6 class="font-weight-bold text-uppercase underline">Details</h6>
                                                </b-row>

                                                <b-row>
                                                    <div class="box__left">
                                                        <p class="font-weight-bold">Phone Number</p>
                                                    </div>
                                                    <div class="right">
                                                        <p>{{data.phone_number}}</p>
                                                    </div>
                                                </b-row>

                                                <b-row>
                                                    <div class="box__left">
                                                        <p class="font-weight-bold">Gender</p>
                                                    </div>
                                                    <div class="right">
                                                        <p>{{capitalizeFirstLetter(data.gender)}}</p>
                                                    </div>
                                                </b-row>

                                                <b-row>
                                                    <div class="box__left">
                                                        <p class="font-weight-bold">Last Seen</p>
                                                    </div>
                                                    <div class="right">
                                                        <div v-if="data.visits != null">
                                                            <p v-if="data.visits.length > 0 ">{{getDateString(data.visits[data.visits.length - 1].created_at)}}</p>
                                                            <p v-else>N/A</p>
                                                        </div>
                                                        <div v-else>
                                                            <p>N/A</p>
                                                        </div>
                                                    </div>
                                                </b-row>

                                                <b-row>
                                                    <div class="box__left">
                                                        <p class="font-weight-bold">Status</p>
                                                    </div>
                                                    <div class="right">
                                                        <p v-if="active" class="active-text">Active Member</p>
                                                        <p v-else >Non-Active Member</p>
                                                    </div>
                                                </b-row>

                                                <b-row v-if="active">
                                                    <div class="box__left">
                                                        <p class="font-weight-bold">Expires</p>
                                                    </div>
                                                    <div class="right">
                                                        <p v-if="data.subscription.subscription_type == 'monthly'" class="active-text">{{getDateString(data.subscription.expiry_date)}}</p>
                                                        <p v-if="data.subscription.subscription_type == 'checkin_days'" class="active-text">{{data.subscription.checkin_days}} days left</p>
                                                    </div>
                                                </b-row>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <router-link
                                                    to="/library"
                                                    class="btn btn-danger px-5 py-3 d-block d-md-inline-block">Library</router-link>
                                            <router-link
                                                    to="/confirm-checkin" v-if="active"
                                                    class="btn ybutton px-5 py-3 d-block d-md-inline-block">Check-in</router-link>
                                        </b-row>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                </div>
            </section>
        </b-row>
    </div>
</template>

<script>
    import Header from "../../../commons/components/Header";
    import moment from "moment"
    export default {
        name: "Customer",
        components: {Header},
        data(){
            return{
                data: {},
                active:false,
                loading: false,
                months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            }
        },
        created() {
            this.data = JSON.parse(localStorage.getItem("customer"));
            this.active = this.data.subscription.status
        },
        mounted() {

        },
        methods:{
            getDateString(date){
              return moment(date).format('DD-MM-YYYY HH:mm:ss');
            },
            capitalizeFirstLetter(string) {
                try{
                  return string.charAt(0).toUpperCase() + string.slice(1);
                }catch (e) {
                  return string;
                }
            },
        },
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";

    .details{
        margin-top: 100px;
    }

    .badger{
        width: 220px;
    }

    .h1{
        font-size: 48px;
        font-weight: 600;
    }

    .h4{
        font-weight: bold;
    }

    .ybutton{
        background: #e3b506;
        margin-left: 10px;
    }

    .highlight{
        color: #c82333;
        font-weight: bold;
    }

    .active-text{
        color: #1c7430;
        font-weight: bold;
    }

    .y-border-bottom{
        border-top: solid 2px #3D454C;
    }

    .y-border-top{
        border-bottom: solid 2px #3D454C;
    }

    .right{
        float: right;
    }
    @media only screen and (max-width: 1281px) {

    }

    @media only screen and (max-width: 1199px) {

        .badger{
            width: 180px;
        }

        .h1{
            font-size: 40px;
            font-weight: 600;
        }

        .h4{
            font-size: 20px;
            font-weight: bold;
        }

    }

    @media only screen and (max-width: 800px) {


    }

    @media only screen and (max-width: 640px) {

    }

    @media only screen and (max-width: 570px) {


    }

    @media only screen and (max-width: 380px) {

    }

    @media only screen and (max-width: 320px) {

    }
</style>