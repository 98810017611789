<template>
    <div class="cart-item">

        <b-row class="header">
            <a class="navbar-brand ml-3">
                <img @click="cancel()" src="../../assets/ic_cancel.png" class="y-icon-header">
            </a>
            <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">
                <h1 class="h5 text-center title">{{data.item.name}}</h1>
                <span class="text-center title-amt" v-if="data.discounts.length > 0">UGX. {{data.item.discount_price}}</span>
                <span class="text-center title-amt" v-else>UGX. {{data.item.price}}</span>
            </div>
            <button @click="save()" class="btn btn-danger py-md-4 px-md-5 m-md-0 px-4 m-2 rounded-0">Save</button>
        </b-row>

        <b-row class="content h-100 w-100 d-flex justify-content-center align-items-center flex-column mb-5">
            <b-col md="8" lg="8" class="mx-auto">
                <!--QTY-->
                <b-row class="space">
                    <b-col>
                        <h5 class="h5 bold">QUANTITY</h5>
                    </b-col>
                </b-row>
                <b-row class="qty-border">
                    <b-col cols="1" class="less qty-btn left">
                        <img @click="reduce()" src="../../assets/ic_minus_qty.png" class="qty-icon">
                    </b-col>
                    <b-col cols="10" class="less text-center">
                        <p class="qty">{{qty}}</p>
                    </b-col>
                    <b-col cols="1" class="less qty-btn right">
                        <img @click="add()" src="../../assets/ic_add_qty.png" class="qty-icon">
                    </b-col>
                </b-row>

                <!--DISCOUNT-->
                <b-row class="space less">
                    <h5 class="h5 bold vert-center">DISCOUNT</h5>
                    <b-col cols="2">
                        <button class="button" @click="addDiscount">Add discount</button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-row class="leaf-still"></b-row>
                        <b-row class="leaf" v-for="(discount, idx) in data.discounts" :key="idx">
                            <b-col cols="9" class="label">
                                <span class="name">{{discount.discount_name}}</span>
                            </b-col>
                            <b-col cols="2" class="label">
                                <div style="float: right;">
                                    <span v-if="discount.discount_type == 'Amount'" class="amount">UGX. </span>
                                    <span class="amount">{{Number(discount.amount).toLocaleString()}}</span>
                                    <span v-if="discount.discount_type == 'Percentage'" class="amount">%</span>
                                </div>
                            </b-col>
                            <b-col cols="1">
                                <img src="../../assets/ic_cancel.png" class="y-icon" @click="removeDiscount"/>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <!--TEAM-->
                <b-row class="space less">
                    <h5 class="h5 bold vert-center">TEAM</h5>
                    <b-col cols="3">
                        <button class="button" @click="addMember">Add team member</button>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12">
                        <b-row class="leaf-still"></b-row>
                        <b-row class="leaf" v-for="(member, idx) in data.team" :key="idx">
                            <b-col cols="11" class="label">
                                <span class="name">{{member.name}}</span>
                            </b-col>
                            <b-col cols="1">
                                <img src="../../assets/ic_cancel.png" class="y-icon" @click="removeMember(idx)"/>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <!--PLAN-->
                <b-row v-if="subscription_item" class="space-2">
                    <b-col>
                        <h5 class="h5 bold">CHOOSE A PLAN FOR THIS PAYMENT</h5>
                    </b-col>
                </b-row>
                <b-row v-if="subscription_item" class="space">
                    <b-col cols="1"></b-col>
                    <b-col cols="10" >
                        <b-row class="plan-select less">
                            <span class="plan-text bold mx-auto">Choose a Plan:</span>
                            <b-col>
                                <b-form-select class="select-input" v-model="payment_plan">
                                    <b-select-option class="option" value="null" disabled>Select a Plan</b-select-option>
                                    <b-select-option class="option" value="Daily" >Daily</b-select-option>
                                    <b-select-option class="option" value="Weekly">Weekly</b-select-option>
                                    <b-select-option class="option" value="Monthly">Monthly</b-select-option>
                                </b-form-select>
                            </b-col>
                        </b-row>

                        <b-row v-if="daily" class="space">
                            <b-col cols="1"></b-col>
                            <b-col cols="10">
                                <b-row class="entry">
                                    <b-col cols="2">
                                        <span>1 Day</span>
                                    </b-col>
                                    <b-col cols="8"></b-col>
                                    <b-col cols="2">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" v-on:input="swtch(1, 'id1')" class="custom-control-input" id="id1" >
                                            <label class="custom-control-label" for="id1"></label>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row class="entry">
                                    <b-col cols="2">
                                        <span>2 Days</span>
                                    </b-col>
                                    <b-col cols="8"></b-col>
                                    <b-col cols="2">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" v-on:input="swtch(2, 'id2')" class="custom-control-input" id="id2">
                                            <label class="custom-control-label" for="id2"></label>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row class="entry">
                                    <b-col cols="2">
                                        <span>3 Days</span>
                                    </b-col>
                                    <b-col cols="8"></b-col>
                                    <b-col cols="2">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" v-on:input="swtch(3, 'id3')" class="custom-control-input" id="id3">
                                            <label class="custom-control-label" for="id3" ></label>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row class="entry">
                                    <b-col cols="2">
                                        <span>4 Days</span>
                                    </b-col>
                                    <b-col cols="8"></b-col>
                                    <b-col cols="2">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" v-on:input="swtch(4, 'id4')" class="custom-control-input" id="id4">
                                            <label class="custom-control-label" for="id4"></label>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row class="entry">
                                    <b-col cols="2">
                                        <span>5 Days</span>
                                    </b-col>
                                    <b-col cols="8"></b-col>
                                    <b-col cols="2">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" v-on:input="swtch(5, 'id5')" class="custom-control-input" id="id5">
                                            <label class="custom-control-label" for="id5" ></label>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row class="entry">
                                    <b-col cols="2">
                                        <span>6 Days</span>
                                    </b-col>
                                    <b-col cols="8"></b-col>
                                    <b-col cols="2">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" v-on:input="swtch(6, 'id6')" class="custom-control-input" id="id6">
                                            <label class="custom-control-label" for="id6"></label>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row class="entry">
                                  <b-col cols="2">
                                    <span>7 Days</span>
                                  </b-col>
                                  <b-col cols="8"></b-col>
                                  <b-col cols="2">
                                    <div class="custom-control custom-switch">
                                      <input type="checkbox" v-on:input="swtch(7, 'id7')" class="custom-control-input" id="id7">
                                      <label class="custom-control-label" for="id7"></label>
                                    </div>
                                  </b-col>
                                </b-row>
                              <b-row class="entry">
                                <b-col cols="2">
                                  <span>13 Days</span>
                                </b-col>
                                <b-col cols="8"></b-col>
                                <b-col cols="2">
                                  <div class="custom-control custom-switch">
                                    <input type="checkbox" v-on:input="swtch(13, 'id13')" class="custom-control-input" id="id13">
                                    <label class="custom-control-label" for="id13"></label>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row class="entry">
                                <b-col cols="2">
                                  <span>14 Days</span>
                                </b-col>
                                <b-col cols="8"></b-col>
                                <b-col cols="2">
                                  <div class="custom-control custom-switch">
                                    <input type="checkbox" v-on:input="swtch(14, 'id14')" class="custom-control-input" id="id14">
                                    <label class="custom-control-label" for="id14"></label>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row class="entry">
                                <b-col cols="2">
                                  <span>21 Days</span>
                                </b-col>
                                <b-col cols="8"></b-col>
                                <b-col cols="2">
                                  <div class="custom-control custom-switch">
                                    <input type="checkbox" v-on:input="swtch(21, 'id21')" class="custom-control-input" id="id21">
                                    <label class="custom-control-label" for="id21"></label>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row class="entry">
                                <b-col cols="2">
                                  <span>28 Days</span>
                                </b-col>
                                <b-col cols="8"></b-col>
                                <b-col cols="2">
                                  <div class="custom-control custom-switch">
                                    <input type="checkbox" v-on:input="swtch(28, 'id28')" class="custom-control-input" id="id28">
                                    <label class="custom-control-label" for="id28"></label>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row class="entry">
                                <b-col cols="2">
                                  <span>29 Days</span>
                                </b-col>
                                <b-col cols="8"></b-col>
                                <b-col cols="2">
                                  <div class="custom-control custom-switch">
                                    <input type="checkbox" v-on:input="swtch(29, 'id29')" class="custom-control-input" id="id29">
                                    <label class="custom-control-label" for="id29"></label>
                                  </div>
                                </b-col>
                              </b-row>
                              <b-row class="entry">
                                <b-col cols="2">
                                  <span>30 Days</span>
                                </b-col>
                                <b-col cols="8"></b-col>
                                <b-col cols="2">
                                  <div class="custom-control custom-switch">
                                    <input type="checkbox" v-on:input="swtch(30, 'id30')" class="custom-control-input" id="id30">
                                    <label class="custom-control-label" for="id30"></label>
                                  </div>
                                </b-col>
                              </b-row>

                            </b-col>
                            <b-col cols="1"></b-col>
                        </b-row>

                        <b-row v-if="weekly" class="space">
                            <b-col cols="1"></b-col>
                            <b-col cols="10">
                                <b-row class="entry">
                                    <b-col cols="2">
                                        <span>1 Week</span>
                                    </b-col>
                                    <b-col cols="8"></b-col>
                                    <b-col cols="2">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" v-on:input="swtch(1, 'id8')" class="custom-control-input" id="id8" >
                                            <label class="custom-control-label" for="id8"></label>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row class="entry">
                                    <b-col cols="2">
                                        <span>2 Weeks</span>
                                    </b-col>
                                    <b-col cols="8"></b-col>
                                    <b-col cols="2">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" v-on:input="swtch(2, 'id9')" class="custom-control-input" id="id9">
                                            <label class="custom-control-label" for="id9"></label>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row class="entry">
                                    <b-col cols="2">
                                        <span>3 Weeks</span>
                                    </b-col>
                                    <b-col cols="8"></b-col>
                                    <b-col cols="2">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" v-on:input="swtch(3, 'id10')" class="custom-control-input" id="id10">
                                            <label class="custom-control-label" for="id10" ></label>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row class="entry">
                                    <b-col cols="2">
                                        <span>4 Weeks</span>
                                    </b-col>
                                    <b-col cols="8"></b-col>
                                    <b-col cols="2">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" v-on:input="swtch(4, 'id11')" class="custom-control-input" id="id11">
                                            <label class="custom-control-label" for="id11"></label>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="1"></b-col>
                        </b-row>

                        <b-row v-if="monthly" class="space">
                            <b-col cols="1"></b-col>
                            <b-col cols="10">
                                <b-row class="entry">
                                    <b-col cols="2">
                                        <span>1 Month</span>
                                    </b-col>
                                    <b-col cols="8"></b-col>
                                    <b-col cols="2">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" v-on:input="swtch(1, 'id12')" class="custom-control-input" id="id12" >
                                            <label class="custom-control-label" for="id12"></label>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row class="entry">
                                    <b-col cols="2">
                                        <span>2 Months</span>
                                    </b-col>
                                    <b-col cols="8"></b-col>
                                    <b-col cols="2">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" v-on:input="swtch(2, 'id13')" class="custom-control-input" id="id13">
                                            <label class="custom-control-label" for="id13"></label>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row class="entry">
                                    <b-col cols="2">
                                        <span>3 Months</span>
                                    </b-col>
                                    <b-col cols="8"></b-col>
                                    <b-col cols="2">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" v-on:input="swtch(3, 'id14')" class="custom-control-input" id="id14">
                                            <label class="custom-control-label" for="id14" ></label>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row class="entry">
                                    <b-col cols="2">
                                        <span>4 Months</span>
                                    </b-col>
                                    <b-col cols="8"></b-col>
                                    <b-col cols="2">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" v-on:input="swtch(4, 'id15')" class="custom-control-input" id="id15">
                                            <label class="custom-control-label" for="id15"></label>
                                        </div>
                                    </b-col>
                                </b-row>
                                <b-row class="entry">
                                    <b-col cols="2">
                                        <span>6 Months</span>
                                    </b-col>
                                    <b-col cols="8"></b-col>
                                    <b-col cols="2">
                                        <div class="custom-control custom-switch">
                                            <input type="checkbox" v-on:input="swtch(6, 'id16')" class="custom-control-input" id="id16">
                                            <label class="custom-control-label" for="id16" ></label>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-col>
                            <b-col cols="1"></b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="1"></b-col>
                </b-row>

                <!--SUBSCRIPTION-->
                <b-row v-if="subscription_item" class="space-2">
                    <b-col>
                        <h5 class="h5 bold">CHOOSE A SUBSCRIPTION TYPE</h5>
                    </b-col>
                </b-row>
                <b-row v-if="subscription_item" class="space">
                    <b-col cols="6" style="border-right: solid 1px #a0a0a0">
                        <b-row>
                            <b-col cols="10">
                                <span class="subscription-text">Standard Monthly Subscription</span>
                            </b-col>
                            <b-col cols="2">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" v-model="sub_monthly" class="custom-control-input" id="idy">
                                    <label class="custom-control-label" for="idy"></label>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="space">
                            <b-col cols="8">
                                <span class="text">
                                    This is the standard type that counts-down your subscription on a daily
                                </span>
                            </b-col>
                        </b-row>
                    </b-col>
                    <b-col cols="6">
                        <b-row>
                            <b-col cols="10">
                                <span class="subscription-text">Count Check-ins</span>
                            </b-col>
                            <b-col cols="2">
                                <div class="custom-control custom-switch">
                                    <input type="checkbox" v-model="checkins" class="custom-control-input" id="idx">
                                    <label class="custom-control-label" for="idx"></label>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="space">
                            <b-col cols="8">
                                <span class="text">
                                    This type of subscription only counts the number of Check-ins
                                </span>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <b-row class="space-2">
                    <b-col cols="1"></b-col>
                    <b-col cols="10">
                        <button class="remove-button" @click="removeItem">REMOVE ITEM</button>
                    </b-col>
                    <b-col cols="1"></b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import router from "../../app/router/router"
    import moment from "moment"
    import {mapGetters} from "vuex";

    export default {
        name: "CartItem",
        data(){
            return{
                data:{},
                cart: JSON.parse(localStorage.getItem("cart")),
                qty: 0,
                idx:null,
                payment_plan: null,
                payment_duration:null,
                pp_id:null,
                daily:false,
                weekly:false,
                monthly:false,
                state:false,
                sub_monthly:false,
                checkins:false,
                subscription_item: false,
            }
        },
        mounted(){
            this.idx = parseInt(window.location.href.split('/')[5])
            this.data = JSON.parse(localStorage.getItem("cart"))[this.idx]
            this.qty = this.data.qty

            if(this.data.subscription_type != null){
                this.subscription_item = true
            }else{
                for(let i = 0; i < this.cart.length; i++){
                    if(this.cart[i].subscription_type != null){
                        this.subscription_item = false
                        break;
                    }else {
                        this.subscription_item = true
                    }
                }
            }

            try{
                if(this.data.subscription_type === "monthly"){
                    this.sub_monthly = true
                }

                if(this.data.subscription_type === "checkin_days"){
                    this.checkins = true
                }

                var sects = this.data.payment_plan.split(" ")

                this.payment_plan = sects[0]
                this.pp_id = this.data.pp_id

                this.$nextTick(function () {
                    if(this.pp_id != null){
                        document.getElementById(this.pp_id).checked = true
                    }
                })

            }catch (e) {
                console.log(e.toString())
            }
        },
        methods:{
            addMember(){
                router.push("/cart-item/team/"+this.idx)
            },
            addDiscount(){
                if(this.data.discounts.length === 0){
                    router.push("/cart-item/discounts/"+this.idx)
                }else{
                    alert("Has a discount already")
                }
            },
            cancel(){
                window.history.back();
            },
            save(){
                this.data.qty = this.qty

                if(this.payment_plan != null && this.payment_duration != null){
                    this.data.payment_plan = this.payment_plan + " " + this.payment_duration
                }
                this.data.pp_id = this.pp_id

                if(this.sub_monthly){
                    this.data.subscription_type = "monthly"
                    this.data.checkin_days = null
                    this.data.paid_date = moment().format("DD/MM/YYYY")
                    this.data.expiry_date = moment().add(parseInt(this.payment_duration), 'day').format("DD/MM/YYYY")
                }

                if(this.checkins){
                    this.data.subscription_type = "checkin_days"
                    this.data.checkin_days = this.payment_duration
                    this.data.paid_date = null
                    this.data.expiry_date = null
                }

                this.cart.splice(parseInt(this.idx), 1)
                this.cart.push(this.data)
                localStorage.removeItem("cart")
                localStorage.setItem("cart", JSON.stringify(this.cart))
                router.push("/library/categories")
            },
            add(){
                this.qty++
            },
            reduce(){
                if(this.qty > 1){
                    this.qty--
                }
            },
            removeItem(){
                if(this.cart.length > 1){
                    this.cart.splice(parseInt(this.idx), 1);
                    localStorage.setItem("cart", JSON.stringify(this.cart))
                }else{
                    localStorage.removeItem("cart")
                }

               window.history.back()
            },
            removeMember(idx){
                var edit = this.cart[this.idx]
                edit.team.splice(parseInt(idx), 1)
                this.data = edit
            },
            removeDiscount(){
                var edit = this.cart[this.idx]
                edit.discounts = []
                this.data = edit
            },
            swtch(pos, id){
                this.pp_id = id
                if(this.payment_plan === "Daily"){
                    this.payment_duration = pos;
                }

                if(this.payment_plan === "Weekly"){
                    this.payment_duration = pos;
                }

                if(this.payment_plan === "Monthly"){
                    this.payment_duration = pos;
                }

                let i = 1;
                for(i = 1; i <= 31; i++){
                    let idc = "id"+i;
                    console.log(idc + " - " +id)
                    if(idc !== id){
                        try{
                            document.getElementById(idc).checked = false
                        }catch (e) {
                            console.log(e.toString())
                        }
                    }
                }
            },
            refreshTogglers(){
                let i = 0;
                for(i=1; i<=16; i++){
                    let idc = "id"+i;
                    try{
                        if(idc != this.pp_id){
                            document.getElementById(idc).checked = false
                        }else{
                            document.getElementById(idc).checked = true
                        }
                    }catch (e) {
                        console.log(e.toString())
                    }
                }
            },
        },
        computed:{
            ...mapGetters(["getCart"]),
            getCheckins(){
                return this.checkins
            },
            getSubMonthly(){
                return this.sub_monthly
            },
            getPpid(){
                return this.pp_id
            },
            getPaymentPlan(){
                return this.payment_plan
            }
        },
        watch:{
            getCheckins(){
                this.sub_monthly = !this.checkins
            },
            getSubMonthly(){
                this.checkins = !this.sub_monthly
            },
            getPaymentPlan(){
                if(this.payment_plan === "Daily"){
                    this.daily = true
                    this.weekly = false
                    this.monthly = false
                }

                if(this.payment_plan === "Weekly"){
                    this.daily = false
                    this.weekly = true
                    this.monthly = false
                }

                if(this.payment_plan === "Monthly"){
                    this.daily = false
                    this.weekly = false
                    this.monthly = true
                }

                this.refreshTogglers()
            }
        }
    }
</script>

<style scoped>
    @import "../../commons/css/main.css";

    .cart-item{
        width: 100%;
    }

    .header{
        position: fixed;
        top: 0;
        left: 0;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 85px;
        z-index: 40;
        box-shadow: 0px 5px 3px #dedede;
        background: white;
    }

    .content{
        margin-top: 6%;
    }

    .y-icon{
        width: 22px;
        height: 22px;
        margin: 10px;
    }

    .y-icon:hover{
        padding: 4px;
        background: #d3d3d3;
        border-radius: 3px;
    }

    .y-icon-header{
        padding: 5px;
        width: 38px;
        height: 38px;
        margin: 16px 10px 10px 10px
    }

    .y-icon-header:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .title{
        font-size: 28px;
        margin-top: 15px;
        font-weight: bold;
        margin-left: 10px;
    }

    .title-amt{
        font-size: 26px;
        margin-top: 8px;
        margin-left: 18px;
    }

    .bold{
        font-weight: bold;
    }

    .button{
        color: white;
        background: #a42933;
        border: #a42933;
        padding: 8px;
        border-radius: 3px;
        margin: 0 auto;
    }

    .button:hover{
        background: #7f1f28;
    }

    .qty-btn{
        color: #fff;
        background: #e8e8e8;
        padding: 5px;
        border: #dedede;
        text-align: center;
    }

    .left{
        border-right: solid 2px #a0a0a0;
    }

    .right{
        border-left: solid 2px #a0a0a0;
    }

    .qty-btn:hover{
        color: #fff;
        background: #cdcdcd;
    }

    .less{
        margin: 0;
        padding: 0;
        width: 100%;
    }
    .qty{
        font-size: 24px;
        margin-top: 10px;
    }

    .qty-border{
        border: solid 2px #a0a0a0;
        margin: 0;
        padding: 0;
        width: 100%;
        border-radius: 3px;
    }

    .qty-icon{
        width: 65px;
        height: 65px;
        padding:12px;
    }

    .space{
        margin-top: 20px;
    }

    .space-2{
        margin-top: 40px;
    }

    .select-input {
        color: #1b1e21;
        font-size: 18px;
        font-weight: bold;
        border: 0;
    }

    .option{
        font-size: 18px;
        font-weight: bold;
    }

    .text{
        font-size: 16px;
    }

    .plan-text{
        font-size: 16px;
        margin-top: 10px;
    }

    .subscription-text{
        font-size: 18px;
        font-weight: bold;
    }

    .entry{
        padding: 10px;
    }

    .plan-select{
        border: solid 2px #a0a0a0;
        margin: 0;
        padding: 10px;
        width: 100%;
        border-radius: 3px;
    }

    .remove-button{
        width: 100%;
        border: 0;
        background: #f1f1f1;
        border-radius: 3px;
        padding: 15px;
        color: #9c2631;
        font-weight: bold;
    }

    .remove-button:hover{
        background: #e1e1e1;
    }

    .vert-center{
        margin-top: 10px;
    }

    .leaf-still{
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: solid 1px #dedede;
        font-weight: bold;
    }

    .leaf{
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: solid 1px #dedede;
    }

    .leaf:hover{
        background: #f2f2f2;
    }

    .label{
        margin-top: 10px;
    }

    .name{
        font-size: 16px;
    }

    .amount{
        font-size: 16px;
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        color: #fff;
        border-color: #c82333;
        background-color: #c82333;
    }

    .custom-control-input:focus ~ .custom-control-label::before {
        box-shadow: 0 0 0 0.2rem rgba(255, 48, 3, 0.25);
    }

    .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
        border-color: #ff88a3;
    }

    .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
        color: #fff;
        background-color: #ffb4c8;
        border-color: #ff90a6;
    }


</style>