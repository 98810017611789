import axios from "axios";
import configs from "../../../../app/configurations/configs";

const IP = configs.SERVER_IP;

const state = {
  response:{},
  error:{},
  status:{}
};

const getters = {
  getCheckinSuccessResponse: (state) => state.response,
  getCheckinErrorResponse: (state) => state.error,
  getCustomerSuccessResponse: (state) => state.response,
  getCustomerErrorResponse: (state) => state.error,

};

const actions = {
  async checkinCustomer({ commit }, id) {
    await axios
        .patch(IP + "/customer-subscriptions/customer/"+id+"/checkin")
        .then((response) => {
          console.log(JSON.stringify(response.data))
          commit("setSuccessResponse", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },

  async createCustomer({ commit }, data) {
    await axios
        .post(IP + "/customers",
            {
                business_id: localStorage.getItem("business_id"),
                name: data.fname +" "+data.lname,
                phone_number: data.phone,
                email: data.email,
                address: data.address,
                gender: data.gender,
                date_of_birth:  data.dob
            }
            )
        .then((response) => {
            console.log(JSON.stringify(response.data))
            commit("setSuccessResponse", response.data);
        })
        .catch((error) => {
            commit("setErrorResponse", error.response);
        });
  },

};

const mutations = {
  setSuccessResponse: (state, response) => {
    state.response = response.data;
  },
  setErrorResponse: (state, response) => {
    state.error = response.data;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
