<template>
    <div class="categories">
        <b-row style="margin:0;">
            <b-col cols="12" style="margin:0; padding: 0;">
                <div class="list-group list-group-flush" v-if="showCategories">
                    <b-overlay :show="loading" sm="rounded">
                        <b-row class="y-list-item" v-for="(category, idx) in getCategories" :key="idx" @click="selectCategory(category.id)">
                            <b-col cols="11">
                                <div class="d-flex align-items-center">
                                    <div :class="[(idx % 2) ? 'item-initials-dark':'item-initials']">
                                        {{getInitial(category.name)}}
                                    </div>
                                    <div>
                                        <p class="mb-0">{{category.name}}</p>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="1">
                                <div class="right-figures">
                                    <img src="../../assets/ic_next.png" class="y-icon" alt="back">
                                </div>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </div>

                <div class="list-group list-group-flush" v-if="showCategoryItems">
                    <b-overlay :show="loading" sm="rounded">
                        <b-row class="y-list-item" v-for="(citem, idx) in getCategoryItems" :key="idx" @click="selectItem(citem)">
                            <b-col cols="10">
                                <div class="d-flex align-items-center">
                                    <div class="item-initials-grey">
                                        {{getInitial(citem.name)}}
                                    </div>
                                    <div>
                                        <p class="mb-0">{{citem.name}}</p>
                                        <p class="desc">{{citem.description}}</p>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="2">
                                <div class="d-flex align-items-center" style="float: right;">
                                  <p class="font-weight-bold mb-0" style="margin-top:20px;">UGX {{Number(citem.price).toLocaleString()}}</p>
                                </div>
                            </b-col>
                        </b-row>
                    </b-overlay>

                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Categories",
        data(){
            return{
                showCategories:true,
                showCategoryItems:false,
                loading: false
            }
        },
        mounted() {
            this.loading = true
            this.fetchCategories()
            this.showCategories = true
        },
        methods:{
            ...mapActions(["fetchCategories", "fetchCategoryItems"]),
            getInitial(name){
                return name.charAt(0).toUpperCase()+name.charAt(1).toLowerCase()
            },
            selectCategory(id){
                this.fetchCategoryItems(id);
                this.loading = true
                this.$root.$emit('items-showing')
            },
            selectItem(item){
              console.log(JSON.stringify(item))
                let data = {
                    item:{
                        id:item.id,
                        name:item.name,
                        price:item.price,
                        category:{
                            name:item.category_name
                        }
                    },
                    team:[],
                    discounts:[],
                    qty:1
                }
                var cart = JSON.parse(localStorage.getItem("cart"))

                if(this.isInCart(item.id)){
                    alert("Already added to your cart")
                }else{
                    if(cart == null){
                      cart = []
                      cart.push(data)
                    }else{
                      cart.push(data)
                    }
                    localStorage.setItem("cart", JSON.stringify(cart))
                    this.$root.$emit('refresh-cart')
                }
            },
            isInCart(id){
                var json = localStorage.getItem("cart")
                if(json != null){
                    if(json.includes(id)){
                        return true
                    }
                }
                return false
            },
            back(){
                this.loading = true
                this.showCategoryItems = false
                this.showCategories = true
                this.fetchCategories()
            }
        },
        computed:{
            ...mapGetters([
               "getCategories", "getCategoryItems",
                "getLibraryErrorResponse",
            ]),
        },
        watch:{
            getCategoryItems(data){
                this.loading = false
                if(data.length > 0){
                    this.showCategoryItems = true
                    this.showCategories = false
                }
            },
            getCategories(){
                this.loading = false
            }
        }
    }
</script>

<style scoped>
    @import "../../commons/css/main.css";

    .categories{
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .y-icon{
        padding: 5px;
        width: 35px;
        margin: 10px;
    }

    .list-group{
        margin-top: 5px;
    }

    .y-list-item{
        width: 100%;
        font-weight: bold;
        border-bottom: solid 1px #a0a0a0;
        margin: 0px 0px 1px 1px;
        padding: 0;
    }

    .y-list-item:hover{
        background: rgba(241, 241, 241, 0.55);
    }

    .item-initials{
        width: 80px;
        height: 80px;
        color: white;
        background: #dc3545;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        flex-shrink: 0;
        margin-right: 15px;
    }

    .item-initials-dark{
        width: 80px;
        height: 80px;
        color: white;
        background: #9c2631;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        flex-shrink: 0;
        margin-right: 15px;
    }

    .item-initials-grey{
        width: 75px;
        height: 80px;
        color: white;
        background: #a0a0a0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        flex-shrink: 0;
        margin-right: 15px;
    }

    .right-figures{
        margin-top: 10px;
    }

    .desc{
        font-size: 12px;
        color: #3D454C;
        font-weight: normal;
    }
</style>