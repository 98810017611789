import axios from "axios";
import qs from "querystring";
import configs from "../../../../app/configurations/configs";

const IP = configs.SERVER_IP;

const state = {
  expenses:[],
  meta:{},
  exp:{},
  response:{},
  error:{}
};

const getters = {
  getExpenses: (state) => state.expenses,
  getExpensesMeta: (state) => state.meta,
  getExpense: (state) => state.exp,
  getExpenseSuccessResponse: (state) => state.response,
  getExpenseErrorResponse: (state) => state.error,
};

const actions = {
  async fetchExpenses({ commit }) {
    await axios
      .get(IP + "/business-expenses/business/"+localStorage.getItem("business_id"))
      .then((response) => {
          commit("setExpensesResponse", response.data);
          commit("setExpensesMetaResponse", response.data);
      })
      .catch((error) => {
        commit("setErrorResponse", error.response);
      });
  },
  async fetchExpensesPaged({ commit }, data) {
      await axios
         .get(IP + "/business-expenses/business/"+localStorage.getItem("business_id")+"?page="+data.page)
         .then((response) => {
             commit("setExpensesResponse", response.data);
             commit("setExpensesMetaResponse", response.data);
          })
          .catch((error) => {
              commit("setErrorResponse", error.response);
         });
  },

  async filterExpenses({ commit }, data) {
      var params = "to="+data.to+"&from="+data.from+"&location="+data.location
      await axios
          .get(IP + "/business-expenses/business/"+localStorage.getItem("business_id")+"?"+params)
          .then((response) => {
              //console.log(JSON.stringify(response.data))
              commit("setExpensesResponse", response.data);
          })
        .catch((error) => {
              commit("setErrorResponse", error.response);
          });
  },
  async deleteExpense({ commit }, data) {
      let params  = qs.stringify({
          reason: data.reason,
      });
      await axios
          .delete(IP + "/business-expenses/"+data.id,
              {
                  headers:{
                      "Content-Type":" application/x-www-form-urlencoded"
                  },
                  data:params
              })
          .then((response) => {
              commit("setExpenseResponse", response.data);
          })
          .catch((error) => {
              commit("setErrorResponse", error.response);
          });
  },

  async addExpense({ commit }, data) {
      var params  = qs.stringify({
          name: data.name,
          amount: data.amount,
          payment_type: data.payment_type,
          reason: data.reason,
          date: data.date,
          location_id: data.location_id,
          business_id: data.business_id
      })
    await axios
        .post(IP + "/business-expenses", params)
        .then((response) => {
          commit("setExpenseSuccessResponse", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
};

const mutations = {
  setExpensesResponse: (state, response) => {
    state.expenses = response.data;
  },
  setExpensesMetaResponse: (state, response) => {
    state.meta = response.paginator;
  },
  setExpenseResponse: (state, response) => {
    state.exp = response.data;
  },
  setExpenseSuccessResponse: (state, response) => {
    state.response = response.data;
  },
  setErrorResponse: (state, response) => {
    state.error = response.data;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
