<template>
    <div class="expense">

        <b-row class="header">
            <a class="navbar-brand ml-3">
                <img @click="back()" src="../../../assets/ic_navigate_back.png" class="y-icon-header">
            </a>
            <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">

            </div>
        </b-row>

        <section class="h-100 w-100 d-flex flex-column ">
            <div class="d-flex justify-content-md-center align-items-center flex-column flex-grow-1 mt-5">
                <div class=" col-md-8 col-lg-7 mx-auto mt-5">
                    <div class="d-flex flex-column align-items-center mt-5 mt-md-0 confirm-sm">
                        <img src="../../../assets/confirm_delete.svg" class="badger">
                        <h3 class="font-weight-bold mt-4">Confirm delete expense</h3>
                        <p class="text-center">Please confirm you would like to delete this expense.</p>
                    </div>
                    <b-overlay :show="loading" rounded="sm">
                        <button class="btn btn-danger d-block py-3 px-5 w-100 mt-5" @click="onDelete">Confirm Delete</button>
                    </b-overlay>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import router from "../../../app/router/router"
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "ConfirmExpenseDelete",
        data(){
            return{
                expense: JSON.parse(localStorage.getItem("expense")),
                loading: false
            }
        },
        methods:{
            ...mapActions(["deleteExpense"]),
            back(){
                window.history.back()
            },
            onDelete(){
                let data = {
                    id:this.expense.id,
                    reason:this.expense.delete_reason
                }
                this.loading = true
                this.deleteExpense(data)
            },
        },
        computed:{
            ...mapGetters([
                "getExpense",
                "getExpenseErrorResponse",
            ]),
        },
        watch:{
            getExpense(){
                this.loading = false
                router.push("/home/expenses")
            },
            getExpenseErrorResponse(data){
                this.loading = false
                alert(data.message)
            }
        }
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";

    .expense{

    }

    .y-icon{
        width: 150px;
    }

    .center{
        margin-top: 180px;
    }

    .text{
        font-size: 26px;
    }

    .header{
        padding: 10px;
        margin: 0;
        width: 100%;
        box-shadow: 0px 5px 3px #dedede;
    }

    .badger{
        width: 90px;
    }

    .y-icon-header{
        padding: 5px;
        width: 38px;
        height: 38px;
        margin: 10px;
    }

    .y-icon-header:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .title{
        color: #000000;
        font-size: 22px;
        font-weight: bold;
        margin-top: 15px;
    }

</style>