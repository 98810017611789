<template>
    <div class="cashier">
        <b-row class="header">
            <b-col cols="1">
                <img src="../../../assets/ic_navigate_back.png" class="y-icon-header" alt="back" @click="back">
            </b-col>

            <b-col cols="10" style="overflow: hidden; text-align: center;">
                <h4 class="title">UGX {{Number(total).toLocaleString()}} {{type}}</h4>
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-row class="top-padding">
                    <b-col cols="12" class="h-100 w-100 d-flex flex-column">
                        <b-row>
                            <div class="d-flex justify-content-md-center align-items-center flex-column flex-grow-1">
                                <p class="h5 text-center mt-5 mb-4 mt-md-0 px-3 px-md-0 text">How much has the customer given you?</p>
                                <b-overlay :show="loading" sm="rounded" style="width: 100%;">
                                    <b-col cols="12" sm="10" md="8" lg="7" class="mx-auto">
                                        <div class="input-group mb-3 input-group-detach">
                                            <input type="text" class="input form-control py-3 py-md-4 px-3" v-model="raw_amount"
                                                   aria-label="Recipient's username" aria-describedby="basic-addon2">

                                            <div class="input-group-append">
                                                <button class="btn btn-danger px-5" type="button" @click="onSubmit()">Tender</button>

                                            </div>
                                        </div>
                                    </b-col>
                                </b-overlay>
                            </div>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import router from "../../../app/router/router";
    import {mapActions, mapGetters} from "vuex"
    export default {
        name: "Tender",
        data(){
            return{
                amount:null,
                total:0,
                type:null,
                loading: false,
                raw_type:null,
                payment_provider:null,
                raw_amount:null,
                customer_id: "",
                no_subscription:true
            }
        },
        mounted() {
            this.raw_type = window.location.href.split('/')[5]

            if(this.raw_type.includes("mobile")){
                var t = this.raw_type.split("-")
                this.payment_provider = t[1]
                this.type = this.capitalizeFirstLetter(t[0].replaceAll("_", " "))
                this.raw_type = t[0]
            }else{
                this.type = this.capitalizeFirstLetter(this.raw_type.replaceAll("_", " "))
            }


            var cart = JSON.parse(localStorage.getItem("cart"))
            var i = 0
            for(i=0; i<cart.length; i++){
                if(cart[i].discounts.length > 0){
                    this.total += parseInt(cart[i].item.discount_price) * cart[i].qty
                }else {
                    this.total += parseInt(cart[i].item.price) * cart[i].qty
                }
            }
            if(JSON.parse(localStorage.getItem("customer")) != null){
                this.customer_id = JSON.parse(localStorage.getItem("customer")).id
            }
        },
        methods:{
            ...mapActions(["makeTransaction", "addSubscription"]),
            onSubmit(){
                if(this.amount != null && parseInt(this.amount) - parseInt(this.total) >= 0){
                    let data = {
                        amount:this.amount,
                        total:this.total,
                        balance: parseInt(this.amount) - this.total,
                        payment_type: this.type,
                    }
                    localStorage.setItem("cashier", JSON.stringify(data))

                    var items = []
                    var subscription_type = ""
                    var payment_plan = ""
                    var duration = ""
                    var cart = JSON.parse(localStorage.getItem("cart"))
                    var i = 0;
                    for(i=0; i<cart.length; i++){
                        var price = 0
                        if(cart[i].discounts.length > 0){
                           price = parseInt(cart[i].item.discount_price)
                        }else{
                           price = parseInt(cart[i].item.price)
                        }
                        let item = {
                            item_id: (cart[i].item.id != null) ? cart[i].item.id : '',
                            name: cart[i].item.name,
                            price: price,
                            item_quantity:cart[i].qty,
                            item_subtotal: parseInt(cart[i].qty) * parseInt(cart[i].item.price),
                            team:cart[i].team,
                            discounts:cart[i].discounts,
                        }
                        if(cart[i].subscription_type != null){
                            subscription_type = cart[i].subscription_type
                            payment_plan = cart[i].payment_plan.split(" ")[0]
                            duration = cart[i].payment_plan.split(" ")[1]
                            item.subscription_type = cart[i].subscription_type
                        }
                        items.push(item)
                    }

                    let order = {
                        customer_id: this.customer_id,
                        business_id: localStorage.getItem("business_id"),
                        location_id: localStorage.getItem("location_id"),
                        total_cash: this.total,
                        payment_type: this.raw_type,
                        payment_provider: this.payment_provider,
                        transaction_details: items
                    }

                    let subscription = {
                        customer_id: this.customer_id,
                        business_id: localStorage.getItem("business_id"),
                        location_id: localStorage.getItem("location_id"),
                        amount: this.total,
                        subscription_type: subscription_type,
                        payment_plan: payment_plan,
                        duration: duration
                    }
                    this.loading = true
                    this.makeTransaction(order)
                    if(subscription_type.length > 0){
                        this.no_subscription = false
                        this.addSubscription(subscription)
                    }
                }else{
                    alert("Receive/Enter enough money from the customer")
                }
            },
            back(){
                window.history.back()
            },
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
        },
        computed:{
            ...mapGetters(["getTransactionErrorResponse", "getTransactionSuccessResponse"]),
            getRawAmount(){
                return this.raw_amount;
            }
        },
        watch:{
            getRawAmount(amt){
                this.amount = amt.replaceAll(",", "")
                var format = amt.replaceAll(",", "")
                if(isNaN(format)){
                    alert("Enter Numbers only")
                    this.raw_amount = null
                    this.amount = null
                }else{
                    this.raw_amount = Number(format).toLocaleString()
                }
            },
            getTransactionSuccessResponse(data){
                console.log(JSON.stringify(data.details[0]))
                this.loading = false
                let cashier = JSON.parse(localStorage.getItem("cashier"))
                cashier.receipt_no = data.receipt_no
                cashier.date = data.created_at
                cashier.points =  data.customer.details.current_points
                localStorage.setItem("cashier", JSON.stringify(cashier))
                router.push("/cashier/balance")
            },
            getTransactionErrorResponse(data){
                this.loading = false
                alert(data.error)
            }
        }
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";

    .header{
        padding: 15px;
    }

    .y-icon-header{
        padding: 5px;
        width: 40px;
        margin-top: 10px;
        margin-left: 15px;
    }

    .top-padding{
        margin-top: 180px;
    }

    .title{
        font-size: 28px;
        font-weight: bold;
        margin-top: 16px;
    }

    .text{
        font-size: 22px;
        margin-top: 16px;
    }

    .input{
        height: 70px;
        font-size: 22px;
    }

</style>