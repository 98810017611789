<template>
    <div class="complete">
        <section class="h-100 w-100 d-flex flex-column ">
            <div class="py-4">
                <a class="navbar-brand ml-3 text-danger font-weight-bold " @click="goHome">
                    New Sale
                </a>
            </div>
            <div class="center d-flex justify-content-md-center align-items-center flex-column flex-grow-1">
                <p class="text-center h4 font-weight-bold mt-5">UGX {{Number(cashier.balance).toLocaleString()}} Balance</p>
                <p class="text-center mb-5 ">Out of UGX {{Number(cashier.total).toLocaleString()}}</p>

                <b-col col md="8" lg="7">
                    <div class="d-flex flex-column align-items-center mt-5 mt-md-0">
                        <img src="../../../assets/ic_done_green.png" class="y-icon"/>

                        <p class="mt-2 d-inline-block text-center">Transaction complete</p>
                    </div>
                </b-col>
            </div>
        </section>
    </div>
</template>

<script>
    import router from "../../../app/router/router";
    export default {
        name: "TransactionComplete",
        data(){
            return{
                cashier : JSON.parse(localStorage.getItem("cashier"))
            }
        },

        methods:{
            goHome(){
                localStorage.removeItem("cashier")
                localStorage.removeItem("cart")
                localStorage.removeItem("customer")
                router.push("/")
            }
        },
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";

    .y-icon{
        padding: 5px;
        width: 125px;
    }

    .center{
        margin-top: 70px;
    }

</style>