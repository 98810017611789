<template>
    <div class="expense">
        <b-row class="header">
            <a class="navbar-brand ml-3">
                <img @click="back()" src="../../../assets/ic_navigate_back.png" class="y-icon-header">
            </a>
            <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">

            </div>
        </b-row>

        <b-row class="content justify-content-md-center">
            <b-col cols="12" class="top-padding">
                <b-row>
                    <b-col cols="12">
                        <h1 class="h5 text-center title">Give a Reason</h1>
                    </b-col>
                </b-row>
                <b-row class="mx-auto py-4 py-md-0 top-padding">
                    <b-col cols="2"></b-col>
                    <b-col cols="8">
                        <div class="input-group input">
                            <textarea type="text" class="form-control border-0" placeholder="Enter a Reason"
                                   aria-label="Recipient's username" v-model="reason" aria-describedby="basic-addon2"/>
                        </div>
                    </b-col>
                    <b-col cols="2"></b-col>
                </b-row>

                <b-row class="mx-auto py-4 py-md-0 top-padding">
                    <b-col cols="2"></b-col>
                    <b-col cols="8">
                        <b-overlay :show="loading" rounded="sm">
                            <button class="btn btn-danger button-text" @click="onNext">Confirm Refund</button>
                        </b-overlay>
                    </b-col>
                    <b-col cols="2"></b-col>
                </b-row>
            </b-col>

        </b-row>
    </div>
</template>

<script>
    import router from "../../../app/router/router";
    import {mapActions, mapGetters} from "vuex";
    export default {
        name: "RefundReason",
        data(){
            return{
                refund: JSON.parse(localStorage.getItem("refund")),
                reason:null,
                loading: false
            }
        },
        methods:{
            ...mapActions(["refundTransaction"]),
            onNext(){
                if(this.reason != null){
                    this.refund.reason = this.reason
                    this.loading = true
                    this.refundTransaction(this.refund)
                }

            },
            back(){
                window.history.back();
            },
        },
        computed:{
            ...mapGetters([
                "getRefundSuccess",
                "getTransactionErrorResponse",
            ]),
        },
        watch:{
            getRefundSuccess(){
                this.loading = false
                router.push("/refund-complete")
            }
        }

    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";

    .expense{
    }

    .header{
        padding: 10px;
        margin: 0;
        width: 100%;
        z-index: 40;
        position: fixed;
        /*box-shadow: 0px 5px 3px #dedede;*/
    }

    .content{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        padding-top: 85px;
    }

    .top-padding{
        margin-top: 100px;
    }
    .y-icon-header{
        padding: 5px;
        width: 38px;
        height: 38px;
        margin: 10px;
    }

    .y-icon-header:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .title{
        color: #000000;
        font-size: 22px;
        font-weight: bold;
        margin-top: 15px;
    }

    .button-text{
        font-weight: bold;
        padding: 20px;
        width: 100%;
        font-size: 18px;
        margin-top: 35px;
    }

    .input{
        border-bottom: solid 1px #a0a0a0;
    }

    input:focus, textarea:focus, select:focus textarea:focus-within{
        outline: none;
    }

</style>