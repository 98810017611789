<template>
    <div class="expense">
        <b-row class="header">
            <a class="navbar-brand ml-3">
                <img @click="back()" src="../../../assets/ic_cancel.png" class="y-icon-header">
            </a>
            <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">
                <h1 class="h5 text-center title">Expense</h1>
            </div>
        </b-row>
        <section class="top h-100 w-100 d-flex justify-content-md-center align-items-center flex-column mb-5">
            <b-row class="mx-auto py-4 py-md-0 fill">
                <b-col cols="2"></b-col>
                <b-col cols="8 mx-auto py-4 py-md-0">
                    <b-col cols="12">
                        <b-row class="entry">
                            <b-col cols="1"></b-col>
                            <b-col cols="3">
                                <span class="font-weight-bold text">
                                    Expense name
                                </span>
                            </b-col>
                            <b-col cols="6">
                                <span class="font-weight-normal border-0 text">{{expense.name}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="entry">
                            <b-col cols="1"></b-col>
                            <b-col cols="3">
                                <span class="font-weight-bold text">
                                    Amount
                                </span>
                            </b-col>
                            <b-col cols="6">
                                <span class="font-weight-normal border-0 text">{{expense.amount}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="entry">
                            <b-col cols="1"></b-col>
                            <b-col cols="3">
                                <span class="font-weight-bold text">
                                   Payment Type
                                </span>
                            </b-col>
                            <b-col cols="6">
                                <span class="font-weight-normal border-0 text">{{expense.payment_type}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="entry">
                            <b-col cols="1"></b-col>
                            <b-col cols="3">
                                <span class="font-weight-bold text">
                                    Reason
                                </span>
                            </b-col>
                            <b-col cols="6">
                                <span class="font-weight-normal border-0 text">{{expense.reason}}</span>
                            </b-col>
                        </b-row>
                        <b-row class="entry">
                            <b-col cols="1"></b-col>
                            <b-col cols="3">
                                <span class="font-weight-bold text">
                                    Date
                                </span>
                            </b-col>
                            <b-col cols="6">
                                <span class="font-weight-normal border-0 text">{{getShortDateString(expense.date)}}</span>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-col>
                <b-col cols="2"></b-col>
            </b-row>

            <b-row class="mx-auto py-4 py-md-0 fill3" v-if="expense.delete_date != null">
                <b-col cols="2"></b-col>
                <b-col cols="8">
                    <b-row class="entry" style="border-top: solid 1px #dedede;">
                        <b-col cols="1"></b-col>
                        <b-col cols="1">
                            <img src="../../../assets/ic_expense_deleted.png" class="y-icon"/>
                        </b-col>
                        <b-col cols="8">
                            <b-row>
                                <span class="font-weight-normal border-0">Expense was deleted</span>
                            </b-row>
                            <b-row>
                                <span class="font-weight-normal border-0 itext">{{getShortDateString(expense.delete_date)}}</span>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row class="expense-delete">
                        <b-col cols="1"></b-col>
                        <b-col cols="11">
                            <b-row>
                                <b-col>
                                    <span class="font-weight-bold">Reason:</span>
                                </b-col>
                            </b-row>
                            <b-row v-if="expense.delete_reason == null">
                                <b-col>
                                    <span class="font-weight-normal border-0 itext" style="font-size: 14px;" >No reason to show</span>
                                </b-col>
                            </b-row>
                            <b-row v-else>
                                <b-col>
                                    <span class="font-weight-normal border-0 itext">{{expense.delete_reason}}</span>
                                </b-col>
                            </b-row>
                        </b-col>

                    </b-row>

                </b-col>
                <b-col cols="2"></b-col>
            </b-row>

            <b-row class="mx-auto py-4 py-md-0 fill2" v-if="expense.status">
                <b-col cols="2"></b-col>
                <b-col cols="8">
                    <button class="btn btn-danger button-text" @click="buttonAction()">Delete</button>
                </b-col>
                <b-col cols="2"></b-col>
            </b-row>

        </section>
    </div>
</template>

<script>
    import router from "../../../app/router/router";

    export default {
        name: "Expense",
        data(){
            return{
                expense:{},
                months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            }
        },
        mounted() {
            this.expense = JSON.parse(localStorage.getItem("expense"))
        },
        methods:{
            buttonAction(){
                router.push("/expense-reason")
            },
            back(){
                localStorage.removeItem("expense")
                window.history.back();
            },
            getDateString(date){
                var sections = date.split("T")
                var sections2 = sections[0].split("-")
                var month_idx = parseInt(sections2[1]) - 1

                return sections2[2]+" "+this.months[month_idx]+", "+sections2[0] +" - "+ this.getTime(date);
            },
            getShortDateString(date){
                var sections = date.split("-")
                var month_idx = parseInt(sections[1]) - 1

                return sections[2]+" "+this.months[month_idx]+", "+sections[0];
            },
            getTime(date){
                var sections = date.split("T")
                var sections2 = sections[1].split(".")
                var sections3 = sections[1].split(":")
                var total = parseInt(sections2[0].replaceAll(":"))
                if(total > 120000){
                    return sections3[0]+":"+sections3[1] + " pm"
                }else{
                    return sections3[0]+":"+sections3[1] + " am"
                }
            },
        },
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";

    .expense{
    }

    .header{
        padding: 10px;
        margin: 0;
        width: 100%;
        box-shadow: 0px 5px 3px #dedede;
    }

    .y-icon{
        width: 38px;
        height: 42px;
        margin-right: 5px;
        margin-left: 15px;
    }

    .y-icon-header{
        padding: 5px;
        width: 38px;
        height: 38px;
        margin: 10px;
    }

    .y-icon-header:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .title{
        color: #000000;
        font-size: 22px;
        font-weight: bold;
        margin-top: 15px;
    }

    .throw span{
        font-weight: bold;
    }

    .top{
        margin-top: 40px;
    }

    .fill{
        width: 100%;
        margin-top: 65px;
    }

    .fill2{
        width: 100%;
    }

    .fill3{
        width: 100%;
        margin-top: 35px;
    }

    .button-text{
        font-weight: bold;
        padding: 20px;
        width: 100%;
        font-size: 18px;
        margin-top: 35px;
    }

    .entry{
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .text{
        font-size: 18px;
        color: #4a4a4a;
    }

    .itext{
        font-size: 18px;
        color: #4a4a4a;
        font-style: italic;
    }

    .input{
        border: solid 1px #a0a0a0;
        border-radius: 6px;
    }

    .expense-delete{
        margin-left: 1px;
    }

</style>