<template>
    <div class="login">
        <section class="h-100 w-100 d-flex justify-content-center align-items-center">
            <b-col cols="12" sm="11" md="7" lg="5" class="mx-auto">
                <div class="card ">
                    <div class="text-danger font-weight-bold h3 mb-4">
                        <img src="../../assets/logo_small_size.png" alt="Yamzit logo">
                    </div>
                    <h1 class="h3">Log in</h1>
                    <p class="mb-5 form-label">Connecting your business to workspace</p>

                    <b-row>
                        <b-col sm="1" md="1" lg="1"/>
                        <b-col cols="12" sm="10" md="10" lg="10" >
                            <b-alert show variant="danger" v-if="hasError">
                            <span class="error-message">
                                {{errorMessage}}
                            </span>
                            </b-alert>
                        </b-col>
                        <b-col sm="1" md="1" lg="1"/>
                    </b-row>

                    <b-form>
                        <div class="form-group">
                            <label class="font-weight-bold form-label">Enter your email</label>
                            <b-form-input id="email" type="email" v-model="phone" class="form-control " placeholder="you@example.com" name="email" value="" required/>

                        </div>
                        <div class="form-group">
                            <label class="font-weight-bold form-label">Enter your password</label>
                            <b-form-input id="password" type="password" v-model="password" class="form-control " placeholder="************"
                                   name="password" required/>
                        </div>
                        <div class="d-flex justify-content-end">
                            <a href="#" class="text-danger label">Forget password</a>
                        </div>

                        <b-overlay :show="loading" rounded="sm">
                            <div style="height: 25px; margin: 15px; color: white;"></div>
                        </b-overlay>

                        <div class="form-group text-center">
                            <button type="button" @click="onSubmit()" class="button btn btn-danger w-100 py-3">
                                Login
                            </button>
                        </div>

                        <p class="label">New to Yamzit? Get started</p>

                    </b-form>
                </div>
            </b-col>
        </section>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import router from "../../app/router/router";
    export default {
        name: "Login",
        data(){
            return {
                phone:null,
                password:null,
                hasError:false,
                errorMessage:null,
                loading:false
            }
        },
        mounted() {
          if(localStorage.getItem("token")!= null && localStorage.getItem("business_id") != null)  {
              router.push("/home/checkout")
          }
        },
        methods:{
            ...mapActions(["accountLogin"]),
            onSubmit() {
                event.preventDefault()
                this.hasError = false
                this.loading = true
                let data = {
                    email_or_phone: this.phone,
                    pin: this.password
                }
                this.accountLogin(data);
            },
        },
        computed: {
            ...mapGetters([
                "getLoginResponse",
                "getLoginErrorResponse",
            ]),
        },
        watch: {
            getLoginResponse(){
                this.loading = false
                router.push("/locations")
            },
            getLoginErrorResponse(data){
                this.loading = false
                this.hasError = true
                this.errorMessage = data.error[0]
            }
        }
    }
</script>

<style scoped>
    @import "../../commons/css/main.css";

    .login{
        padding: 5%;
    }

    .error-message{
        font-size: 14px;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .form-label{

    }

    .card{
        border: solid 1px #dedede;
        width: 100%;
        padding: 30px;
    }

    @media only screen and (max-width: 570px){
        .error-message{
            font-size: 12px;
        }

        .form-label{
            font-size: 12px;
        }

        .label{
            font-size: 12px;
        }

        .button{
            font-size: 12px;
        }

        .h3{
            font-size: 18px;
        }
    }

    @media only screen and (max-width: 380px){
        .error-message{
            font-size: 11px;
        }

        .form-label{
            font-size: 11px;
        }

        .label{
            font-size: 11px;
        }

        .button{
            font-size: 11px;
        }

        .h3{
            font-size: 16px;
        }

        .card{
            border: solid 1px #fff;
        }
    }
</style>