<template>
    <div>
        <LocationHeader title="Choose Your Location"/>

        <b-row style="margin-top: 100px;">
            <section class="h-100 w-100 d-flex justify-content-center align-items-center flex-column px-4  px-md-0">
                <p class="title text-center h6 mb-5">Select the location for which you are tracking.</p>
                <b-col col md="7" lg="6" class="mx-auto">
                    <b-overlay :show="loading" rounded="sm">
                        <div class="y-list-group">
                            <a class="y-list-group-item"
                               @click="loadLocation(location.id, location.location_name+' ('+location.address+')')"
                               v-for="(location, idx) in getLocations" :key="idx">
                                {{location.location_name}} ({{location.address}})
                            </a>
                        </div>
                    </b-overlay>
                </b-col>
            </section>
        </b-row>

    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    import router from "../../app/router/router";
    import LocationHeader from "../../commons/components/LocationHeader";
    export default {
        name: "Locations",
        components: {LocationHeader},
        data(){
          return{
              location_id:null,
              loading: true
          }
        },
        mounted() {
            this.fetchLocations()
        },
        methods:{
            ...mapActions(["fetchLocations"]),
            loadLocation(id, name) {
                localStorage.setItem("location_id", id)
                localStorage.setItem("location_name", name)
                this.location_id = id
                router.push("/home/checkout")
            },
        },
        computed: {
            ...mapGetters([
                "getLocations",
                "getLocationErrorResponse",
            ]),
        },
        watch: {
            getLocations(){
                this.loading = false
            },
            getLocationErrorResponse(){
                this.loading = false
                window.location.reload()
            }
        }
    }
</script>

<style scoped>
    @import "../../commons/css/main.css";

    .y-list-group {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        border: solid 1px #a0a0a0;
        border-radius: 6px;
    }

    .y-list-group-item{
        font-size: 18px;
        padding: 15px;
        border-bottom: solid 1px #a0a0a0;
    }

    .y-list-group-item:hover{
        background: #e9e9e9;
        border-bottom: solid 1px #dedede;
    }

    .title{
        font-size: 18px;
    }

    @media only screen and (max-width: 1281px) {

    }

    @media only screen and (max-width: 1199px) {


    }

    @media only screen and (max-width: 800px) {


    }

    @media only screen and (max-width: 640px) {

    }

    @media only screen and (max-width: 570px) {


    }

    @media only screen and (max-width: 380px) {
        .title{
            font-size: 16px;
        }

        .y-list-group-item{
            font-size: 16px;
            padding: 12px;
        }
    }

    @media only screen and (max-width: 320px) {
        .title{
            font-size: 14px;
        }

        .y-list-group-item{
            font-size: 14px;
            padding: 10px;
        }
    }
</style>