import axios from "axios";
import configs from "../../../../app/configurations/configs";

const IP = configs.SERVER_IP;

const state = {
  response:{},
  error:{},
};

const getters = {
  getCheckoutSuccessResponse: (state) => state.response,
  getCheckoutErrorResponse: (state) => state.error,

};

const actions = {
  async checkout({ commit }, phone) {
    await axios
      .get(IP + "/customers/"+phone+"?business_id="+localStorage.getItem("business_id"))
      .then((response) => {
        console.log(JSON.stringify(response.data))
        commit("setSuccessResponse", response.data);
      })
      .catch((error) => {
        commit("setErrorResponse", error.response);
      });
  },
};

const mutations = {
  setSuccessResponse: (state, response) => {
    state.response = response.data;
  },
  setErrorResponse: (state, response) => {
    state.error = response.data;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
