<template>
    <div class="library">

        <b-row>
            <b-sidebar id="sidebar" ref="mySidebar" title=""  shadow
                       aria-labelledby="sidebar-no-header-title"
                       no-header
                       backdrop-variant="dark"
                       backdrop>

                <template #footer="{ }">

                    <div class="bg-dark text-light align-items-center px-3 py-2 ft">
                        <b-row>
                            <div class="d-flex">
                                <div class="logo">
                                    <img src="../../assets/logo.png" alt="logo" class="dp">
                                </div>
                                <div class="shop-details">
                                    <span class="font-weight-bold">{{name}}</span><br/>
                                    <span class="small">{{location}}</span>
                                </div>
                            </div>
                        </b-row>
                        <b-row>
                            <div class="logout">
                                <button class="logout-btn" @click="logout()">Logout</button>
                            </div>
                        </b-row>
                    </div>

                </template>

                <div class="px-3 py-2 side-menu bg-dark">
                    <div class="y-menu-list-group">
                        <a @click="loadPage('/library/categories')" :class="[mCurrentPage == '/library/categories' ? 'm-active':'y-menu-list-group-item']">
                            CheckOut
                        </a>

                        <a @click="loadPage('/home/transactions')" :class="[mCurrentPage == '/home/transactions' ? 'm-active':'y-menu-list-group-item']">
                            Transactions
                        </a>

                        <a v-if="loyalty" @click="loadPage('/home/redeem')" :class="[mCurrentPage == '/home/redeem' ? 'm-active':'y-menu-list-group-item']">
                            Redeems
                        </a>

                        <a @click="loadPage('/home/expenses')" :class="[mCurrentPage == '/home/expenses' ? 'm-active':'y-menu-list-group-item']">
                            Expenses
                        </a>

                        <a @click="loadPage('/home/apps')"  :class="[mCurrentPage == '/home/apps' ? 'm-active':'y-menu-list-group-item']">
                            Apps
                        </a>
                    </div>
                </div>
            </b-sidebar>
        </b-row>

        <b-row>
            <b-col cols="6" >
                <b-row>
                    <div class="header">
                        <b-row>
                            <b-col cols="1" sm="2" md="2" lg="2" xl="2">
                                <img @click="back()" src="../../assets/ic_navigate_back.png" class="y-icon" alt="back" v-if="loyalty || subscriptions">
                                <img v-else v-b-toggle.sidebar src="../../assets/ic_grid.png" class="y-icon"/>
                            </b-col>
                            <b-col cols="8" sm="7" md="7" lg="7" xl="7" style="overflow: hidden; text-align: center;">
                                <h4 class="title">{{title}}</h4>
                            </b-col>
                            <b-col cols="3" sm="3" md="3" lg="3" xl="3">
                                <b-row>
                                    <b-col cols="6">
                                        <img @click="loadList()" src="../../assets/ic_list.png" :class="[currentPage == '/library/categories' ? 'active':'y-icon']" alt="back">
                                    </b-col>

                                    <b-col cols="6">
                                        <img @click="loadCalculator()" src="../../assets/ic_calculator.png" :class="[currentPage == '/library/calculator' ? 'active':'y-icon']" alt="back">
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </div>
                </b-row>

                <b-row>
                    <router-view/>
                </b-row>
            </b-col>

            <b-col cols="6"  style="border-left: solid 1px #dedede; padding: 0; width: 100%;">
                <b-row>
                    <NullHeader/>
                </b-row>

                <b-row>
                    <Cart/>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import NullHeader from "../../commons/components/NullHeader";
    import Cart from "./Cart";
    import router from "../../app/router/router";
    export default {
        name: "Library",
        components: {Cart, NullHeader},
        data(){
            return{
                activeClass:'m-active',
                currentPage: this.$route.fullPath,
                mCurrentPage: this.$route.fullPath,
                title:"Library",
                name: JSON.parse(localStorage.getItem("business")).name,
                location: localStorage.getItem("location_name"),
                loyalty:JSON.parse(localStorage.getItem('business')).active_products[1].Loyalty,
                subscriptions:JSON.parse(localStorage.getItem('business')).active_products[2].Subscriptions,
                showingItems: false
            }
        },
        mounted() {
            this.$root.$on("items-showing", this.itemsShowing)
        },
        methods:{
            back(){
                if(this.showingItems){
                    router.push("/library/categories")
                    router.go()
                    this.showingItems = false
                }else{
                    localStorage.removeItem("customer")
                    localStorage.removeItem("cart")
                    router.push("/home/checkout")
                }
            },
            itemsShowing(){
                this.showingItems = true
            },
            loadCalculator(){
                router.push("/library/calculator")
            },
            loadList(){
                router.push("/library/categories")
            },
            closeSideMenu(){
                this.$refs.mySidebar.hide();
            },

            loadPage(route){
                router.push(route)
            },
            titleParser(route){
                var name = route.split("/")[2]
                return this.capitalizeFirstLetter(name)
            },
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            logout(){
                localStorage.clear()
                router.push("/")
            }
        },
    }
</script>

<style scoped>
    @import "../../commons/css/main.css";

    .library{
        margin-left: 0;
    }

    .header{
        width: 100%;
        padding: 5px 10px;
        box-shadow: 0px 5px 3px #dedede;
        margin-left: 15px;
    }

    .y-icon{
        padding: 5px;
        width: 45px;
        margin: 10px;
    }

    .y-icon:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .active{
        padding: 6px;
        background: #eeeeee;
        width: 45px;
        margin: 10px;
        border-radius: 6px;
    }

    .title{
        color: #3d454c;
        display: inline-block;
        padding: 10px;
        margin-top: 5px;
        font-weight: bold;
    }

    .section-title{
        font-size: 22px;
        margin-top: 25px;
        font-weight: bold;
        margin-left: 10px;
    }

    .dp{
        padding: 5px;
        width: 60px;
    }

    .logo{
        margin-top: 1px;
        margin-left: 5px;
    }

    .logout{
        margin-left: 50px;
        padding: 0;
        margin-top: 10px;
    }

    .shop-details{
        margin-top: 10px;
    }

    .side-menu{
        background: #3d3d3d;
        padding: 10px;
        height: 100%;
    }

    .m-active{
        font-size: 28px;
        padding: 15px;
        margin: 6px;
        font-weight: bold;
    }

    .logout-btn{
        background: #00000000;
        color: white;
        font-weight: bold;
        padding: 5px;
        border: 0;
        border-radius: 3px;
        font-size: 12px;
        margin-left: 10px;
    }

    .logout-btn:hover{
        background: #535353;
    }

    .y-menu-icon{
        padding: 5px;
        width: 45px;
        margin: 20px 10px 20px 20px;
    }

    .y-menu-icon:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .y-menu-list-group {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
    }

    .y-menu-list-group a{
        text-decoration: none;
        color: #a0a0a0;
    }

    .y-menu-list-group a:hover{
        color: white;
    }

    .y-menu-list-group-item{
        color: #a0a0a0;
        font-size: 28px;
        padding: 15px;
        margin: 6px;
    }

    .y-menu-list-group .m-active{
        color: white;
    }

    @media only screen and (max-width: 1281px) {

    }

    @media only screen and (max-width: 1199px) {


    }

    @media only screen and (max-width: 800px) {


    }

    @media only screen and (max-width: 640px) {

    }

    @media only screen and (max-width: 570px) {
        .y-icon{
            padding: 5px;
            width: 38px;
            margin-top: 14px;
        }

        .title{
            font-size: 20px;
            margin-top: 10px;
        }

    }

    @media only screen and (max-width: 380px) {
        .y-icon{
            padding: 5px;
            width: 32px;
            margin-top: 14px;
        }

        .title{
            font-size: 16px;
            margin-top: 10px;
        }
    }

    @media only screen and (max-width: 320px) {

    }

</style>