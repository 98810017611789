import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../../views/auth/Login";
import Locations from "../../views/location/Locations";
import Checkout from "../../views/home/checkout/Checkout";
import Apps from "../../views/home/apps/Apps";
import Expenses from "../../views/home/expenses/Expenses";
import Transactions from "../../views/home/transactions/Transactions";
import CustomerSignup from "../../views/home/checkout/CustomerSignup";
import Customer from "../../views/home/checkout/Customer";
import NewExpense from "../../views/home/expenses/NewExpense";
import ConfirmExpense from "../../views/home/expenses/ConfirmExpense";
import Library from "../../views/library/Library";
import Calculator from "../../views/library/Calculator";
import Categories from "../../views/library/Categories";
import Redeems from "../../views/home/redeems/Redeems";
import Expense from "../../views/home/expenses/Expense";
import PaymentTypeSelector from "../../views/library/checkout/PaymentTypeSelector";
import Cashier from "../../views/library/checkout/Cashier";
import Tender from "../../views/library/checkout/Tender";
import TransactionComplete from "../../views/library/checkout/TransactionComplete";
import TransactionPoints from "../../views/library/checkout/TransactionPoints";
import Receipt from "../../views/library/checkout/Receipt";
import CustomerBalance from "../../views/library/checkout/CustomerBalance";
import CartItem from "../../views/library/CartItem";
import RedeemCustomer from "../../views/home/redeems/RedeemCustomer";
import Rewards from "../../views/home/redeems/Rewards";
import RedeemedItem from "../../views/home/redeems/RedeemedItem";
import RedeemLibrary from "../../views/home/redeems/RedeemLibrary";
import ExpenseReason from "../../views/home/expenses/ExpenseReason";
import ConfirmExpenseDelete from "../../views/home/expenses/ConfirmExpenseDelete";
import ConfirmCheckinAction from "../../views/home/checkout/ConfirmCheckinAction";
import CheckinComplete from "../../views/home/checkout/CheckinComplete";
import RedeemComplete from "../../views/home/redeems/RedeemComplete";
import RedeemReceipt from "../../views/home/redeems/RedeemReceipt";
import RefundComplete from "../../views/home/transactions/RefundComplete";
import RefundReason from "../../views/home/transactions/RefundReason";
import MomoSelector from "../../views/library/checkout/MomoSelector";
import ItemTeam from "../../views/library/ItemTeam";
import ItemDiscounts from "../../views/library/ItemDiscounts";
import TransactionReceipt from "../../views/home/transactions/TransactionReceipt";
import RedeemOTP from "@/views/home/redeems/RedeemOTP";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    redirect:"/login",
    component: Login
  },
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/locations",
    name: "Locations",
    component: Locations
  },

  {
    path: "/home/checkout",
    name: "Checkout",
    component: Checkout
  },

  {
    path: "/home/transactions",
    name: "Transactions",
    component: Transactions
  },

  {
    path: "/home/expenses",
    name: "Expenses",
    component: Expenses
  },

  {
    path: "/home/redeem",
    name: "Redeems",
    component: Redeems
  },

  {
    path: "/home/apps",
    name: "Apps",
    component: Apps
  },

  {
    path: "/confirm-checkin",
    name: "ConfirmCheckinAction",
    component: ConfirmCheckinAction
  },

  {
    path: "/checkin-complete",
    name: "CheckinComplete",
    component: CheckinComplete
  },

  {
    path: "/new-expense",
    name: "NewExpense",
    component: NewExpense
  },
  {
    path: "/home/expense",
    name: "Expense",
    component: Expense
  },

  {
    path: "/expense-reason",
    name: "Expense",
    component: ExpenseReason
  },

  {
    path: "/expense-confirm-delete",
    name: "ExpenseDelete",
    component: ConfirmExpenseDelete
  },

  {
    path: "/confirm-expense",
    name: "ConfirmExpense",
    component: ConfirmExpense
  },
  {
    path: "/customer-signup/:id",
    name: "CustomerSignup",
    component: CustomerSignup
  },

  {
    path: "/customer",
    name: "Customer",
    component: Customer
  },

  {
    path: "/redeem-customer",
    name: "Customer",
    component: RedeemCustomer
  },

  {
    path: "/redeem-item",
    name: "RedeemItem",
    component: RedeemedItem
  },

  {
    path: "/redeem-library",
    name: "RedeemLibrary",
    component: RedeemLibrary
  },

  {
    path: "/redeem-complete",
    name: "RedeemComplete",
    component: RedeemComplete
  },

  {
    path: "/redeem-receipt",
    name: "RedeemReceipt",
    component: RedeemReceipt
  },

  {
    path: "/rewards",
    name: "Rewards",
    component: Rewards
  },

  {
    path: "/print-receipt",
    name: "TransactionReceipt",
    component: TransactionReceipt
  },

  {
    path: "/refund-reason",
    name: "RefundReason",
    component: RefundReason
  },

  {
    path: "/refund-complete",
    name: "RefundComplete",
    component: RefundComplete
  },

  {
    path: "/cart/item/:id",
    name: "CartItem",
    component: CartItem
  },

  {
    path: "/cart-item/team/:id",
    name: "ItemTeam",
    component: ItemTeam
  },

  {
    path: "/cart-item/discounts/:id",
    name: "ItemTeam",
    component: ItemDiscounts
  },

  {
    path: "/complete/redeem-otp",
    name: "RedeemOTP",
    component: RedeemOTP,
  },

  {
    path: "/library",
    name: "Library",
    component: Library,
    redirect:"library/categories",
    children:[
      {
        path:"categories",
        component:Categories
      },
      {
        path:"calculator",
        component:Calculator
      },
    ]
  },
  {
    path: "/cashier",
    name: "Cashier",
    component: Cashier,
    redirect:"cashier/payment-types",
    children:[
      {
        path:"payment-types",
        component:PaymentTypeSelector
      },
      {
        path:"momo-selector",
        component:MomoSelector
      },
      {
        path:"tender/:id",
        component:Tender
      },
      {
        path:"balance",
        component:CustomerBalance
      },
      {
        path:"receipt",
        component:Receipt
      },
      {
        path:"points",
        component:TransactionPoints
      },
      {
        path:"complete",
        component:TransactionComplete
      },
    ]
  },

];

export default new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});
