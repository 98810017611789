<template>
    <div class="expense">

        <b-row class="header">
            <a class="navbar-brand ml-3">
                <img @click="back()" src="../../../assets/ic_cancel.png" class="y-icon-header">
            </a>
            <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">
                <h1 class="h5 text-center title">New Expense</h1>
            </div>
            <button @click="save()" class="btn btn-danger py-md-4 px-md-5 m-md-0 px-4 m-2 rounded-0">Save</button>
        </b-row>
        <section class="content h-100 w-100 d-flex justify-content-center align-items-center flex-column mb-5">
            <div class=" col-md-8 col-lg-8 mx-auto">
                <div class="mt-5  d-none d-md-block">
                    <b-overlay :show="loading" rounded="sm">
                        <table class="table w-100 font-weight-bold table-bordered">
                            <tbody>
                            <tr>
                                <th class="bg-light">Expense name</th>
                                <td class="font-weight-normal">
                                    <b-form-input
                                            class="input"
                                            v-model="form.name"
                                            type="text"
                                            placeholder="Expense Name"/>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light">Amount</th>
                                <td class="font-weight-normal">
                                    <b-form-input
                                            class="input"
                                            v-model="raw_amount"
                                            type="text"
                                            placeholder="Amount"/>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light">Payment type</th>
                                <td class="font-weight-normal">
                                    <div class="d-flex justify-content-around w-100">
                                        <button id="1" @click="selectType('cash', '1')" :class="[id == 1 ? 'active px-3' : 'button px-3']">Cash</button>
                                        <button id="2" @click="selectType('mobile money', '2')" :class="[id == 2 ? 'active px-3' : 'button px-3']">Mobile money</button>
                                        <button id="3" @click="selectType('card', '3')" :class="[id == 3 ? 'active px-3' : 'button px-3']">Bank/Card</button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light">Reason</th>
                                <td class="font-weight-normal">
                                <textarea v-model="form.reason"
                                          class="border-0 form-control"
                                          placeholder="Reason"
                                          cols="30" rows="7"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <th class="bg-light">Date</th>
                                <td class="font-weight-normal">
                                    <input
                                            class="input"
                                            v-model="form.date"
                                            type="date"
                                            placeholder="Date"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-overlay>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import router from "../../../app/router/router"
    import moment from "moment"

    export default {
        name: "NewExpense",
        data(){
            return{
                months_short:["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                form:{
                    name:null,
                    amount:null,
                    payment_type:null,
                    reason:null,
                    date:null,
                    location_id: localStorage.getItem("location_id"),
                    business_id: localStorage.getItem("business_id")
                },
                id:null,
                loading:false,
                raw_date:null,
                raw_amount:null
            }
        },
        mounted() {
            localStorage.removeItem("new-expense")
        },
        methods:{
            ...mapActions(["fetchExpenses", "fetchExpense", "addExpense"]),
            onSearch(){

            },
            back(){
                window.history.back();
            },
            save(){
                this.loading = true
                this.addExpense(this.form)
            },
            selectType(value, id){
                this.form.payment_type = value
                this.id = id
            },
            getDateToday(){
                var today = Date();
                var sections = today.split(" ")
                var idx = this.months_short.indexOf(sections[1], 0) +  1
                var month = idx
                if(idx < 10){
                    month = "0"+idx
                }
                return  sections[3]+"-"+month+"-"+sections[2]
            },
            convertDate(date){
                var sections = date.split("-")
                return sections[2] +"-"+sections[1] +"-"+sections[0]
            }
        },
        computed:{
            ...mapGetters([
                "getExpenses", "getExpense",
                "getExpenseErrorResponse",
                "getExpenseSuccessResponse",
            ]),
            getRawAmount(){
                return this.raw_amount
            },
            getDate(){
                return this.form.date
            }
        },
        watch:{
            getRawAmount(amt){
                this.form.amount = amt.replaceAll(",", "")
                var format = amt.replaceAll(",", "")
                this.raw_amount = Number(format).toLocaleString()
            },
            getExpenseSuccessResponse(){
                this.loading = false
                router.push("/confirm-expense")
            },
            getExpenseErrorResponse(data){
                this.loading = false
                alert(data.message)
            },
            getDate(date){
                if(date != null){
                    var today = moment(this.getDateToday())
                    var then = moment(date)

                    if(today.diff(then) < 0){
                        alert("You've entered a future date")
                        this.form.date = null
                    }
                }
            }
        }
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";

    .expense{

    }

    .header{
        /*margin: 0;*/
        /*width: 100%;*/
        /*box-shadow: 0px 5px 3px #dedede;*/
        padding: 0;
        margin: 0;
        width: 100%;
        height: 85px;
        box-shadow: 0px 5px 3px #dedede;
    }

    .content{
        margin-top: 20px;
    }

    .y-icon-header{
        padding: 5px;
        width: 38px;
        height: 38px;
        margin: 16px 10px 10px 10px
    }

    .y-icon-header:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .title{
        font-size: 22px;
        margin-top: 15px;
        font-weight: bold;
        margin-left: 10px;
    }

    .input{
        width: 100%;
        font-size: 16px;
        justify-content: left;
        text-align: left;
    }

    .active{
        background: #db0000;
        color: white;
        padding: 5px;
        border: 0;
        border-radius: 3px;
    }

    .button{
        color: #fff;
        background: #a0a0a0;
        padding: 5px;
        border: 0;
        border-radius: 3px;
    }

    .button:hover{
        color: #fff;
        background: #5e5e5e;
    }
</style>