<template>
    <div class="header">
        <b-row>
            <b-col cols="1" sm="2" md="3" lg="3" xl="4">
            </b-col>
            <b-col cols="10" sm="8" md="6" lg="6" xl="4" style="overflow: hidden; text-align: center;">
                <p class="title"></p>
            </b-col>
            <b-col cols="1" sm="2" md="3" lg="3" xl="4"/>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: "NullHeader",
        methods:{

        },
    }
</script>

<style scoped>
    .header{
        width: 100%;
        padding: 5px 10px;
        box-shadow: 0px 5px 2px #dedede;
    }

    .title{
        color: #3d454c;
        display: inline-block;
        padding: 18.2px;
        margin-top: 5px;
        font-weight: bold;
    }

</style>