<template>
    <div>

        <b-row>
            <b-sidebar id="sidebar" ref="mySidebar" title=""  shadow
                       aria-labelledby="sidebar-no-header-title"
                       no-header
                       backdrop-variant="dark"
                       backdrop>

                <template #footer="{ }">

                    <div class="bg-dark text-light align-items-center px-3 py-2 ft">
                        <b-row>
                            <div class="d-flex">
                                <div class="logo">
                                    <img src="../../../assets/logo.png" alt="logo" class="dp">
                                </div>
                                <div class="shop-details">
                                    <span class="font-weight-bold">{{name}}</span><br/>
                                    <span class="small">{{location}}</span>
                                </div>
                            </div>
                        </b-row>
                        <b-row>
                            <div class="logout">
                                <button class="logout-btn" @click="logout()">Logout</button>
                            </div>
                        </b-row>
                    </div>

                </template>

                <div class="px-3 py-2 side-menu bg-dark">

                    <div class="y-menu-list-group">
                        <a @click="loadPage(checkoutPath)" :class="[currentPage == '/home/checkout' ? 'active':'y-menu-list-group-item']">
                            Checkout
                        </a>

                        <a @click="loadPage('/home/transactions')" :class="[currentPage == '/home/transactions' ? 'active':'y-menu-list-group-item']">
                            Transactions
                        </a>

                        <a v-if="loyalty" @click="loadPage('/home/redeem')" :class="[currentPage == '/home/redeem' ? 'active':'y-menu-list-group-item']">
                            Redeems
                        </a>

                        <a @click="loadPage('/home/expenses')" :class="[currentPage == '/home/expenses' ? 'active':'y-menu-list-group-item']">
                            Expenses
                        </a>

                        <a @click="loadPage('/home/apps')"  :class="[currentPage == '/home/apps' ? 'active':'y-menu-list-group-item']">
                            Apps
                        </a>
                    </div>
                </div>
            </b-sidebar>
        </b-row>

        <b-row class="header">
            <b-col col cols="8" sm="9" md="9" lg="10" xl="10">
                <b-row>
                    <img v-b-toggle.sidebar src="../../../assets/ic_grid.png" class="y-menu-icon"/>
                    <div style="margin-top: auto; margin-bottom: auto;">
                        <span class="section-title">Expenses</span>
                    </div>
                </b-row>
            </b-col>

            <b-col col cols="4" sm="3" md="3" lg="2" xl="2" style="margin: auto">
                <button @click="newExpense()" class="button">New Expenses</button>
            </b-col>
        </b-row>
        <!--content-->
        <b-row class="content">
            <b-col cols="11" sm="11" md="11" lg="11" class="mx-auto mt-5">
                <b-row>
                    <b-col cols="2" sm="4" md="4" lg="6" xl="6"></b-col>
                    <b-col cols="10" sm="8" md="8" lg="6" xl="6">
                        <b-row>
                            <b-col cols="6">
                                <b-row class="select-labels">
                                    <div style="margin-top: auto; margin-bottom: auto;">
                                        <img src="../../../assets/ic_calendar.png" class="y-s-icon">
                                    </div>
                                    <span style="margin-top: auto; margin-bottom: auto; margin-left: 5px;">
                                        Select Date
                                    </span>
                                </b-row>
                                <b-row style="width: 100%;">
                                    <date-range-picker
                                            class="select"
                                            ref="picker"
                                            :locale-data="{format: 'dd-mm-yyyy' }"
                                            :minDate="null" :maxDate="null"
                                            :singleDatePicker="false"
                                            :timePicker="false"
                                            :timePicker24Hour="false"
                                            :showWeekNumbers="true"
                                            :showDropdowns="true"
                                            :autoApply="true"
                                            v-model="date_range"
                                            :linkedCalendars="true">
                                        <template style="width: 100%;">
                                                        <span style="font-size: 11px;">
                                                            {{ date_range.startDate }} - {{ date_range.endDate}}
                                                        </span>
                                        </template>
                                    </date-range-picker>
                                </b-row>
                            </b-col>
                            <b-col cols="6">
                                <b-row class="select-labels">
                                    <b-col>
                                        Select Locations
                                    </b-col>
                                </b-row>
                                <b-form>
                                    <b-form-select v-model="filter_location" class="select">
                                        <b-select-option value="null">All Locations</b-select-option>
                                        <b-select-option v-for="(location, idx) in getLocations" :key="idx" :value="location.id">
                                            {{location.location_name}} ({{location.address}})
                                        </b-select-option>
                                    </b-form-select>
                                </b-form>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <b-row class="top-padding">
                   <b-col cols="6">
                       <b-row>
                           <b-col>
                               <p class="totals">{{getExpenses.length}}</p>
                           </b-col>
                       </b-row>
                       <b-row >
                           <b-col>
                               <p class="titles font-weight-bold">EXPENSES</p>
                           </b-col>
                       </b-row>
                   </b-col>
                   <b-col cols="6">
                        <b-row>
                            <b-col>
                                <p class="totals move-right">UGX {{Number(totalExpenses).toLocaleString()}}</p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col>
                                <p class="titles font-weight-bold move-right">TOTAL EXPENSES</p>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

                <b-overlay :show ="loading" rounded="sm">
                    <b-row v-if="getExpensesMeta.current_page > 1">
                    <b-col cols="4"></b-col>
                    <b-col cols="4" class="align-content-center text-center" style="margin: 10px;">
                      <button
                          class="button"
                          @click="lazyLoadUp">
                        Load  Previous ({{getExpensesMeta.current_page}}/{{getExpensesMeta.last_page}})
                      </button>
                    </b-col>
                    <b-col cols="4"></b-col>
                  </b-row>
                    <b-row v-for="(expense, idx) in getExpenses" :key="idx">
                        <b-col cols="12">
                            <b-row class="list-header">
                                <b-col cols="6" sm="4" md="4" lg="4">
                                    <span class="text">{{getDateString(expense.date)}}</span>
                                </b-col>
                                <b-col cols="3" sm="4" md="4" lg="4" class="text-center">
                                    <span class="text">Location</span>
                                </b-col>
                                <b-col cols="3" sm="4" md="4" lg="4" class="text-center">
                                    <div class="total-money">
                                        <span class="text">UGX. {{Number(expense.total).toLocaleString()}}</span>
                                    </div>
                                </b-col>
                            </b-row>

                            <b-row v-for="(data, idx) in expense.expenses" :key="idx"
                                   class="list-item"
                                   @click="showExpense(data.id, data)">

                                <b-col cols="6" col sm="4" md="4" lg="4" class="text-center">
                                    <b-row class="text-center left-padding">
                                        <b-col  cols="3">

                                            <b-row>
                                                <img v-if="data.status == 0" src="../../../assets/ic_expense_deleted.png" class="y-icon"/>
                                                <img v-else-if="data.payment_type == 'cash'" src="../../../assets/ic_cash.png" class="y-icon"/>
                                                <img v-else-if="data.payment_type == 'mobile money'" src="../../../assets/ic_mobile_money.png" class="y-icon"/>
                                                <img v-else-if="data.payment_type == 'card'" src="../../../assets/ic_card.png" class="y-icon"/>

                                                <div class="label">
                                                    <span class="text">{{getTime(data.created_at)}}</span>
                                                </div>
                                            </b-row>
                                        </b-col>

                                        <b-col  cols="8">
                                            <div class="label float-left">
                                                <span class="text">{{data.name}}</span>
                                            </div>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col col cols="3" sm="4" md="4" lg="4" class="text-center">
                                    <span class="text">{{data.location.location_name}} ({{data.location.address}})</span>
                                </b-col>
                                <b-col col cols="3" sm="4" md="4" lg="4" class="text-center">
                                    <div class="total-money">
                                        <span class="text">UGX. {{Number(data.amount).toLocaleString()}}</span>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row style="margin-top: 20px; margin-bottom: 20px;">
                    <b-col cols="1">
                      <button class="button" @click="lazyLoadUp">Prev</button>
                    </b-col>
                    <b-col cols="1" class="text-center">
                      <p class="pages">{{getExpensesMeta.current_page}}/{{getExpensesMeta.last_page}}</p>
                    </b-col>
                    <b-col cols="1">
                      <button class="button" @click="lazyLoadDown">Next</button>
                    </b-col>
                    <b-col cols="9"></b-col>
                  </b-row>
                </b-overlay>
            </b-col>
        </b-row>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import moment from "moment"
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    import router from "../../../app/router/router";

    export default {
        name: "Expenses",
        components:{DateRangePicker},
        data(){
            return{
                months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                months_short:["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                totalExpenses: 0,
                activeClass:'active',
                currentPage: this.$route.fullPath,
                title: this.titleParser(this.$route.fullPath),
                name: JSON.parse(localStorage.getItem("business")).name,
                location: localStorage.getItem("location_name"),
                loyalty:JSON.parse(localStorage.getItem('business')).active_products[1].Loyalty,
                subscriptions:JSON.parse(localStorage.getItem('business')).active_products[2].Subscriptions,
                checkoutPath: "/home/checkout",
                loading: true,
                filter_location:null,
                date_range:{
                    startDate:null,
                    endDate:null
                }
            }
        },
        mounted() {
            if(localStorage.getItem("token") == null){
                router.push("/")
            }else{
                this.fetchExpenses()
            }

            if(!this.loyalty){
                this.checkoutPath = "/library"
            }

            if(!this.subscriptions){
                this.checkoutPath = "/library"
            }

            this.fetchLocations()
        },
        methods:{
            ...mapActions(["fetchExpenses", "fetchExpensesPaged", "filterExpenses", "fetchLocations"]),
            newExpense(){
                router.push("/new-expense")
            },
            filter(){
                var to = this.formatDate(this.date_range.endDate)
                var from = this.formatDate(this.date_range.startDate)
                let data = {
                    to: to,
                    from: from,
                    location: this.filter_location
                }
                this.loading = true
                this.filterExpenses(data)
            },
          lazyLoadUp(){
            if(parseInt(this.getExpensesMeta.current_page) - 1 > 0){
              let data = {
                page:parseInt(this.getExpensesMeta.current_page) - 1
              }
              this.loading = true
              this.fetchExpensesPaged(data)
            }
          },
          lazyLoadDown(){
            if(parseInt(this.getExpensesMeta.current_page) + 1 <= parseInt(this.getExpensesMeta.last_page)){
              let data = {
                page:parseInt(this.getExpensesMeta.current_page) + 1
              }
              this.loading = true
              this.fetchExpensesPaged(data)
            }
          },
            getDateString(date){
                var sections = date.split("-")
                var month_idx = parseInt(sections[1]) - 1

                return sections[2]+" "+this.months[month_idx]+", "+sections[0];
            },
            getTime(date){
                var sections = date.split("T")
                var sections2 = sections[1].split(".")
                var sections3 = sections[1].split(":")
                var total = parseInt(sections2[0].replaceAll(":", ""))
                if(total > 120000){
                    return sections3[0]+":"+sections3[1] + " pm"
                }else{
                    return sections3[0]+":"+sections3[1] + " am"
                }
            },
            formatDate(date){
                return moment(date).format("DD-MM-YYYY")
            },
            showExpense(id, data){
                localStorage.setItem("expense", JSON.stringify(data))
                router.push("/home/expense")
            },
            closeSideMenu(){
                this.$refs.mySidebar.hide();
            },
            loadPage(route){
                router.push(route)
            },
            titleParser(route){
                var name = route.split("/")[2]
                return this.capitalizeFirstLetter(name)
            },
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            logout(){
                localStorage.clear()
                router.push("/")
            }
        },
        computed:{
            ...mapGetters([
                "getExpenses", "getExpensesMeta",
                "getExpenseErrorResponse","getLocations",
            ]),
            getDateRange(){
                return this.date_range
            },
            getFilterLocation(){
                return this.filter_location
            },
        },
        watch:{
            getDateRange(){
                this.filter()
            },
            getFilterLocation(){
                this.filter()
            },
            getExpenses(data){
                this.totalExpenses = 0
                this.loading = false
                var i = 0
                for(i < 0; i < data.length; i++){
                    this.totalExpenses += parseInt(data[i].total)
                }
            },
            getExpenseErrorResponse(){
                this.loading = false
            }
        }
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";
    @import "../../../commons/css/menu.css";
    @import "../../../commons/css/utils.css";

    .header{
        margin: 0;
        padding: 0;
        width: 100%;
        z-index: 20;
        position: fixed;
        box-shadow: 0px 5px 3px #dedede;
        background: white;
    }

    .content{
        margin-top: 90px;
    }

    .select{
        width: 100%;
    }

    .select-labels{
        margin-bottom: 5px;
        vertical-align: center;
        font-weight: bold;
    }

    .button{
      width: 100%;
      background: #c82333;
      color: white;
      border: solid 1px #c82333;
      border-radius: 3px;
      font-size: 14px;
      padding: 10px;
    }

    .y-icon{
        width: 22px;
        height: 22px;
        margin-right: 5px;
    }

    .y-s-icon{
        width: 22px;
        height: 22px;
        margin: 0;
        padding: 0;
    }

    .totals{
        font-size: 22px;
        font-weight: bold;
    }

    .titles{
        color: #a0a0a0;
    }

    .list-header{
        width: 100%;
        background: #eaeaea;
        padding-bottom: 15px;
        padding-top: 15px;
        margin: 0;
    }

    .list-header span{
        font-weight: bold;
    }

    .list-item{
        width: 100%;
        background: white;
        padding-bottom: 15px;
        padding-top: 15px;
        margin: 0;
        border-bottom: solid 1px #f1f1f1;
    }

    .list-item:hover{
        background: #dddddd;
    }

    .total-money{
        float: right;
    }

    .left-padding{
        margin-left: 5px;
    }

    @media only screen and (max-width: 1285px){

    }

    @media only screen and (max-width: 1119px){

    }

    @media only screen and (max-width: 800px) {

        .totals{
            font-size: 18px;
        }

        .titles{
            font-size: 14px;
        }

    }

    @media only screen and (max-width: 640px) {
        .button{
            height: 55px;
            font-size: 12px;
        }

        .y-icon{
            width: 20px;
            height: 20px;
        }

        .y-s-icon{
            width: 20px;
            height: 20px;
            margin: 0;
            padding: 0;
        }

        .text{
            font-size: 12px;
        }

        .totals{
            font-size: 16px;
        }

        .titles{
            font-size: 12px;
            line-height: 2px;
        }

        .list-header{
            padding-bottom: 4px;
            padding-top: 4px;
        }

        .label{
            margin-top: 1px;
        }

        .s-label{
            margin-top: 1px;
        }
    }

    @media only screen and (max-width: 570px) {
        .content{
            margin-top: 45px;
        }

        .button{
            height: 45px;
            font-size: 11px;
        }

        .y-icon{
            width: 16px;
            height: 16px;
        }

        .y-s-icon{
            width: 16px;
            height: 16px;
            margin: 0;
            padding: 0;
        }

        .text{
            font-size: 11px;
        }

        .totals{
            font-size: 14px;
        }

        .titles{
            font-size: 11px;
            line-height: 0px;
        }

        .list-header{
            padding-bottom: 2px;
            padding-top: 2px;
        }

        .label{
            margin-top: -2px;
        }

        .s-label{
            margin-top: 3px;
        }

        .select-labels{
            font-size: 12px;
        }
    }

    @media only screen and (max-width: 380px) {
        .y-icon{
            width: 15px;
            height: 15px;
        }

        .button{
            font-size: 11px;
            height: 40px;
        }

        .select-labels{
             font-size: 12px;
         }

        .text{
            font-size: 9px;
        }

        .list-header{
            padding-bottom: 3px;
            padding-top: 2px;
        }

        .total-money{
            margin-top: 8px;
        }
    }

    @media only screen and (max-width: 320px) {

    }

</style>