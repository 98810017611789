<template>
    <div class="selector">
        <section class="h-100 w-100 d-flex flex-column">
            <b-row>
                <b-col cols="1" class="header">
                    <img src="../../../assets/ic_navigate_back.png" class="y-icon-header" alt="back" @click="back">
                </b-col>
                <b-col cols="10" style="overflow: hidden; text-align: center;">
                    <p class="text-center h4 font-weight-bold title">UGX {{Number(amount).toLocaleString()}} Mobile Money</p>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-md-center align-items-center flex-column flex-grow-1 space">
                <p class="text-center mb-5 text">Select a service provider to use</p>
                <b-col col md="8" lg="7" class="mx-auto">
                    <b-row>
                        <b-col cols="3" @click="select('mtn')" class="option">
                            <b-row style="text-align: center; padding: 0;">
                                <b-col>
                                    <img src="../../../assets/mtn_money.png" class="y-icon">
                                </b-col>
                            </b-row>
                            <b-row style="text-align: center; padding: 0;">
                                <b-col>
                                    <span class="text-center">MTN Mobile Money</span>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="3" @click="select('airtel')" class="option">
                            <b-row style="text-align: center; padding: 0;">
                                <b-col>
                                    <img src="../../../assets/airtel_money.png" class="y-icon">
                                </b-col>
                            </b-row>
                            <b-row style="text-align: center; padding: 0;">
                                <b-col>
                                    <span class="text-center">Airtel Money</span>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="3" @click="select('africell')" class="option">
                            <b-row style="text-align: center; padding: 0;">
                                <b-col>
                                    <img src="../../../assets/africell_money.png" class="y-icon">
                                </b-col>
                            </b-row>
                            <b-row style="text-align: center; padding: 0;">
                                <b-col>
                                    <span class="text-center">Africell Money</span>
                                </b-col>
                            </b-row>
                        </b-col>
                        <b-col cols="3" @click="select('other_mps')" class="option">
                            <b-row style="text-align: center; padding: 0;">
                                <b-col>
                                    <img src="../../../assets/other_mobile_money.png" class="y-icon">
                                </b-col>
                            </b-row>
                            <b-row style="text-align: center; padding: 0;">
                                <b-col>
                                    <span class="text-center">Other mobile payment services</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-col>
            </div>
        </section>
    </div>
</template>

<script>
    import router from "../../../app/router/router";
    export default {
        name: "MomoSelector",
        data(){
            return{
                amount:0
            }
        },
        mounted() {
            var cart = JSON.parse(localStorage.getItem("cart"))
            var i = 0
            for(i=0; i<cart.length; i++){
                this.amount += parseInt(cart[i].item.price) * cart[i].qty
            }
        },
        methods:{
            back(){
                window.history.back()
            },
            select(option){
                console.log(option)
                router.push("/cashier/tender/mobile_money-"+option)
            }
        }
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";

    .selector{

    }

    .header{
        padding: 15px;
    }

    .space{
        margin-top: 110px;
    }

    .option{
        background: white;
    }

    .option:hover{
        background: #f1f1f1;
        border: solid 1px #dedede;
        border-radius: 3px;
    }

    .y-icon{
        padding: 5px;
        width: 75%;
        height: 200px;
        margin: 10px;
        border-radius: 3px;
    }

    .y-icon-header{
        padding: 5px;
        width: 40px;
        margin-top: 10px;
        margin-left: 15px;
    }

    .text{
        margin: auto;
        font-size: 20px;
        padding: 10px;
    }

    .title{
        margin-top: 24px;
    }
</style>