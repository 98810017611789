<template>
    <div>
        <b-row>
            <b-sidebar id="sidebar" ref="mySidebar" title=""  shadow
                       aria-labelledby="sidebar-no-header-title"
                       no-header
                       backdrop-variant="dark"
                       backdrop>

                <template #footer="{ }">

                    <div class="bg-dark text-light align-items-center px-3 py-2 ft">
                        <b-row>
                            <div class="d-flex">
                                <div class="logo">
                                    <img src="../../../assets/logo.png" alt="logo" class="dp">
                                </div>
                                <div class="shop-details">
                                    <span class="font-weight-bold">{{name}}</span><br/>
                                    <span class="small">{{location}}</span>
                                </div>
                            </div>
                        </b-row>
                        <b-row>
                            <div class="logout">
                                <button class="logout-btn" @click="logout()">Logout</button>
                            </div>
                        </b-row>
                    </div>

                </template>

                <div class="px-3 py-2 side-menu bg-dark">
                    <div class="y-menu-list-group">
                        <a @click="loadPage(checkoutPath)" :class="[currentPage == '/home/checkout' ? 'active':'y-menu-list-group-item']">
                            Checkout
                        </a>

                        <a @click="loadPage('/home/transactions')" :class="[currentPage == '/home/transactions' ? 'active':'y-menu-list-group-item']">
                            Transactions
                        </a>

                        <a @click="loadPage('/home/redeem')" :class="[currentPage == '/home/redeem' ? 'active':'y-menu-list-group-item']">
                            Redeems
                        </a>

                        <a @click="loadPage('/home/expenses')" :class="[currentPage == '/home/expenses' ? 'active':'y-menu-list-group-item']">
                            Expenses
                        </a>

                        <a @click="loadPage('/home/apps')"  :class="[currentPage == '/home/apps' ? 'active':'y-menu-list-group-item']">
                            Apps
                        </a>
                    </div>
                </div>
            </b-sidebar>
        </b-row>

        <b-row class="header">
            <b-col cols="5" sm="5" md="3" lg="3">
                <b-row>
                    <img v-b-toggle.sidebar src="../../../assets/ic_grid.png" class="y-menu-icon"/>
                    <div style="margin-top: auto; margin-bottom: auto;">
                        <span class="section-title">Redeem</span>
                    </div>
                </b-row>
            </b-col>
        </b-row>
        <!--content-->
        <b-row class="content clearfix d-flex flex-column align-content-center flex-grow-1 justify-content-center">
            <b-col cols="12" sm="12" md="12" lg="12">
                <b-row>
                    <b-col cols="12">
                        <p class="h5 text-center title">Please enter the customers Phone No.</p>
                    </b-col>
                </b-row>
                <b-row class="clearfix">
                    <b-col cols="1" sm="2" md="2" lg="2" xl="3"></b-col>
                    <b-col cols="10" sm="8" md="8" lg="8" xl="6">
                        <b-row class="align-content-center fill-width-parent" style="margin: 0; padding: 0;">
                            <b-col cols="9" sm="9" md="10" lg="10" style="margin: 0; padding: 0;">
                                <input type="text"
                                       class="input form-control py-3 py-md-4 px-3"
                                       v-model="phone"
                                       placeholder="Enter customers phone number…"
                                       aria-label="Recipient's username"
                                       aria-describedby="basic-addon2"/>
                            </b-col>
                            <b-col cols="3" sm="3" md="2" lg="2" style="margin: 0; padding: 0;">
                                <b-overlay :show="loading" rounded="sm">
                                    <button class="button"
                                            type="button"
                                            @click="onSubmit()">Enter</button>
                                </b-overlay>
                            </b-col>
                        </b-row>

                    </b-col>
                    <b-col cols="1" sm="2" md="2" lg="2" xl="3"></b-col>
                </b-row>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import router from "../../../app/router/router";
    import {mapActions, mapGetters} from "vuex";

    export default {
        name: "Redeems",
        data(){
            return{
                activeClass:'active',
                currentPage: this.$route.fullPath,
                title: this.titleParser(this.$route.fullPath),
                name: JSON.parse(localStorage.getItem("business")).name,
                location: localStorage.getItem("location_name"),
                subscriptions:JSON.parse(localStorage.getItem('business')).active_products[2].Subscriptions,
                checkoutPath:  "/home/checkout",
                phone:null,
                loading: false
            }
        },
        mounted() {
            if(localStorage.getItem("token") == null){
                router.push("/")
            }

            if(!this.loyalty){
                this.checkoutPath = "/library"
            }

            if(!this.subscriptions){
                this.checkoutPath = "/library"
            }
        },
        methods:{
            ...mapActions(["fetchRedeemCustomer"]),
            onSubmit(){
                this.loading = true
                this.fetchRedeemCustomer(this.phone)
            },
            verifyMSISDN(number){
                var result = "";
                if(number.includes('+')){
                    result = number.substring(1, number.length)
                }else if(number.substring(0, 3) == "256"){
                    result = number
                }else{
                    result = "256"+number.substring(1, number.length)
                }
                return result;
            },
            closeSideMenu(){
                this.$refs.mySidebar.hide();
            },

            loadPage(route){
                router.push(route)
            },
            titleParser(route){
                var name = route.split("/")[2]
                return this.capitalizeFirstLetter(name)
            },
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            logout(){
                localStorage.clear()
                router.push("/")
            }
        },
        computed: {
            ...mapGetters([
                "getRedeemCustomerSuccessResponse",
                "getRedeemCustomerErrorResponse",
            ]),
        },
        watch:{
            getRedeemCustomerSuccessResponse(data){
                this.loading = false
                localStorage.setItem("redeem_customer", JSON.stringify(data))
              router.push("/redeem-customer")
            },
            getRedeemCustomerErrorResponse(data){
                alert(data.error)
            }
        }
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";
    @import "../../../commons/css/menu.css";
    @import "../../../commons/css/utils.css";

    .header{
        padding: 0;
        margin: 0;
        width: 100%;
        z-index: 40;
        position: fixed;
        box-shadow: 0px 5px 3px #dedede;
    }

    .content{
        position: absolute;
        width: 100%;
        padding-top: 385px;
    }

    .title{
        font-size: 22px;
        font-weight: bold;
    }

    .input{
        width: 100%;
        height: 70px;
        font-size: 22px;
        border-radius: 1px;
    }

    .button{
        width: 100%;
        height: 70px;
        background: #c82333;
        color: white;
        border: solid 1px #c82333;
        border-radius: 0px 3px 3px 0px;
    }

    @media only screen and (max-width: 1285px){
        .content{
            padding-top: 300px;
        }
    }

    @media only screen and (max-width: 1119px){
        .content{
            padding-top: 555px;
        }
    }

    @media only screen and (max-width: 800px) {

        .content{
            padding-top: 450px;
        }

        .title{
            font-size: 18px;
        }

        .input{
            font-size: 18px;
            height: 60px;
        }

        .button{
            height: 60px;
        }

    }

    @media only screen and (max-width: 640px) {

        .content{
            padding-top: 440px;
        }

        .title{
            font-size: 16px;
        }

        .input{
            font-size: 16px;
            height: 55px;
        }

        .button{
            font-size: 14px;
            height: 55px;
        }
    }

    @media only screen and (max-width: 570px) {
        .title{
            font-size: 14px;
        }

        .input{
            font-size: 12px;
        }

        .button{
            font-size: 12px;
        }

        .content{
            padding-top: 350px;
        }
    }

    @media only screen and (max-width: 380px) {
        .input{
            height: 45px;
        }

        .button{
            font-size: 11px;
            height: 45px;
        }

        .content{
            padding-top: 250px;
        }
    }

    @media only screen and (max-width: 320px) {

    }

</style>