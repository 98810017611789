<template>
    <div class="customer">
        <Header title="Customer"/>
        <b-row class="details">
            <section class="h-100 w-100 d-flex flex-column ">
                <div class="d-flex justify-content-md-center align-items-center flex-column flex-grow-1 px-3  pb-5 pb-md-0 ">
                    <div class=" col-md-8 col-lg-7 mx-auto">
                        <div class="row">
                            <div class="col-md-4 text-center">
                                <div class="box-100">
                                    <img v-if="active" src="../../../assets/ic_active_user.png" class="badger"/>
                                    <img v-else src="../../../assets/non_active_user.png" class="badger"/>
                                </div>
                                <div class="user-points">
                                    <p class="h1" v-if="data.details">{{data.details.current_points}}</p>
                                    <p class="h1" v-else>0</p>
                                    <p class="h4">Points</p>
                                </div>

                            </div>
                            <div class="col-md-8 user-details">
                                <h1 class="h3">{{data.name}}</h1>
                                <div class="border-bottom border-top border-sm-light my-4 py-3">
                                    <h6 class="font-weight-bold text-uppercase underline">Details</h6>

                                    <b-col cols="12">

                                        <b-row>
                                            <div class="box__left">
                                                <p class="font-weight-bold">Phone Number</p>
                                            </div>
                                            <div class="box__right">
                                                <p>{{data.phone_number}}</p>
                                            </div>
                                        </b-row>

                                        <b-row>
                                            <div class="box__left">
                                                <p class="font-weight-bold">Last Seen</p>
                                            </div>
                                            <div class="box__right">
                                                <p>N/A</p>
                                            </div>
                                        </b-row>

                                    </b-col>
                                </div>

                                <router-link
                                        to="/rewards"
                                        class="btn btn-danger px-5 py-3 d-block d-md-inline-block">Redeem</router-link>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </b-row>
    </div>
</template>

<script>
    import Header from "../../../commons/components/Header";
    export default {
        name: "RedeemCustomer",
        components: {Header},
        data(){
            return{
                data: {},
                active:false,
                months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            }
        },
        mounted() {
            this.data = JSON.parse(localStorage.getItem("redeem_customer"));
            if(this.data.subscription != null){
                this.active = true
            }else{
                this.active = false
            }
        },
        methods:{
            getDateString(date){
                var sections = date.split("T")
                var sections2 = sections[0].split("-")
                var month_idx = parseInt(sections2[1]) - 1

                return sections2[2]+" "+this.months[month_idx]+", "+sections2[0] +" - "+ this.getTime(date);
            },
            getTime(date){
                var sections = date.split("T")
                var sections2 = sections[1].split(".")
                var sections3 = sections[1].split(":")
                var total = parseInt(sections2[0].replaceAll(":"))
                if(total > 120000){
                    return sections3[0]+":"+sections3[1] + " pm"
                }else{
                    return sections3[0]+":"+sections3[1] + " am"
                }
            },
        },
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";

    .details{
        margin-top: 200px;
    }

    .badger{
        width: 200px;
    }

    .h1{
        font-size: 42px;
        font-weight: 600;
        line-height: 30px;
    }

    .h4{
        font-size: 22px;
        font-weight: bold;
    }

    .user-points{
        margin-top: 40px;
    }

    .ybutton{
        background: #e3b506;
        margin-left: 10px;
    }

    .highlight{
        color: #c82333;
        font-weight: bold;
    }

</style>