<template>
    <div>
        <b-row>
            <b-sidebar id="sidebar" ref="mySidebar" title=""  shadow
                       aria-labelledby="sidebar-no-header-title"
                       no-header
                       backdrop-variant="dark"
                       backdrop>

                <template #footer="{ }">

                    <div class="bg-dark text-light align-items-center px-3 py-2 ft">
                        <b-row>
                            <div class="d-flex">
                                <div class="logo">
                                    <img src="../../../assets/logo.png" alt="logo" class="dp">
                                </div>
                                <div class="shop-details">
                                    <span class="font-weight-bold">{{name}}</span><br/>
                                    <span class="small">{{location}}</span>
                                </div>
                            </div>
                        </b-row>
                        <b-row>
                            <div class="logout">
                                <button class="logout-btn" @click="logout()">Logout</button>
                            </div>
                        </b-row>
                    </div>
                </template>

                <div class="px-3 py-2 side-menu bg-dark">
                    <div class="y-menu-list-group">
                        <a @click="loadPage(checkoutPath)" :class="[currentPage == '/home/checkout' ? 'active':'y-menu-list-group-item']">
                            Checkout
                        </a>

                        <a @click="loadPage('/home/transactions')" :class="[currentPage == '/home/transactions' ? 'active':'y-menu-list-group-item']">
                            Transactions
                        </a>

                        <a v-if="loyalty" @click="loadPage('/home/redeem')" :class="[currentPage == '/home/redeem' ? 'active':'y-menu-list-group-item']">
                            Redeems
                        </a>

                        <a @click="loadPage('/home/expenses')" :class="[currentPage == '/home/expenses' ? 'active':'y-menu-list-group-item']">
                            Expenses
                        </a>

                        <a @click="loadPage('/home/apps')"  :class="[currentPage == '/home/apps' ? 'active':'y-menu-list-group-item']">
                            Apps
                        </a>
                    </div>
                </div>
            </b-sidebar>
        </b-row>

        <b-row class="header">
            <b-col cols="5" sm="5" md="3" lg="3">
                <b-row>
                    <img v-b-toggle.sidebar src="../../../assets/ic_grid.png" class="y-menu-icon"/>
                    <div style="margin-top: auto; margin-bottom: auto;">
                        <span class="section-title">Apps</span>
                    </div>
                </b-row>
            </b-col>
        </b-row>
        <!--content-->
        <b-row class="content">
            <b-col cols="1"></b-col>
            <b-col cols="10">
                <div class="h-100 w-100s">
                    <div class="mt-5 px-3">
                        <h2 class="font-weight-bold"> Active apps</h2>
                        <p>Handpicked solutions for businesses like yours.</p>

                        <div class="row mt-5 flex-wrap">
                            <div class="col-md-4 my-2">
                                <div class="d-flex bg-light rounded py-4 px-3 h-100 ">
                                    <div class="icon">
                                        <img src="../../../assets/ic_cash.png" class="y-xicon">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold">Dashboard</p>
                                        <p>Build a customer loyalty program straight from your point of sale or your Square Online
                                            website</p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4  my-2  ">
                                <div class="d-flex bg-light rounded py-4 px-3 h-100 ">
                                    <div class="icon">
                                        <img src="../../../assets/ic_cash.png" class="y-xicon">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold">Point of sale</p>
                                        <p>Point of sale solutions to fit your business. Because every business is different, Yamzit has
                                            a variety of POS options that you can use</p>
                                        <p class="text-success text-right">
                                            <img src="../../../assets/ic_indi_tick.png" class="y-micon">
                                            Active
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4   my-2 ">
                                <div class="d-flex bg-light rounded py-4 px-3 h-100 ">
                                    <div class="icon">
                                        <img src="../../../assets/ic_cash.png" class="y-xicon">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold">Appointments</p>
                                        <p>The all-in-one point of sale for booking, payments, and more. We handle the admin while you
                                            do more of what you love.</p>
                                        <p class="text-danger text-right">
                                            <img src="../../../assets/ic_indi_tick.png" class="y-micon">
                                            Renew
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="mt-5 bg-light px-3 py-5">
                        <h2 class="font-weight-bold"> Most popular apps</h2>
                        <p>Explore the apps that a wide range of merchants rely on.</p>

                        <div class="row flex-wrap">
                            <div class="col-md-4  ">
                                <div class="d-flex bg-light rounded py-4 px-3 h-100 ">
                                    <div class="icon rounded">
                                        <img src="../../../assets/ic_cash.png" class="y-xicon">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold">Marketplace</p>
                                        <p>Create a mobile-friendly online store in under an hour.</p>
                                        <p>
                                            $ 16.34 / month
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4  ">
                                <div class="d-flex bg-light rounded py-4 px-3 h-100 ">
                                    <div class="icon rounded">
                                        <img src="../../../assets/ic_cash.png" class="y-xicon">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold">Store Manager</p>
                                        <p>Create a mobile-friendly online store in under an hour.</p>
                                        <p>
                                            $ 16.34 / month
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4  ">
                                <div class="d-flex bg-light rounded py-4 px-3 h-100 ">
                                    <div class="icon rounded">
                                        <img src="../../../assets/ic_cash.png" class="y-xicon">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold">Marketplace</p>
                                        <p>Create a mobile-friendly online store in under an hour.</p>
                                        <p>
                                            $ 16.34 / month
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4  ">
                                <div class="d-flex bg-light rounded py-4 px-3 h-100 ">
                                    <div class="icon rounded">
                                        <img src="../../../assets/ic_cash.png" class="y-xicon">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold">Appointments</p>
                                        <p>The all-in-one point of sale for booking, payments, and more.</p>
                                        <p>
                                            $ 16.34 / month
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4  ">
                                <div class="d-flex bg-light rounded py-4 px-3 h-100 ">
                                    <div class="icon rounded">
                                        <img src="../../../assets/ic_cash.png" class="y-xicon">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold">Subscriptions</p>
                                        <p>Create a mobile-friendly online store in under an hour.</p>
                                        <p>
                                            $ 16.34 / month
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4  ">
                                <div class="d-flex bg-light rounded py-4 px-3 h-100 ">
                                    <div class="icon rounded">
                                        <img src="../../../assets/ic_cash.png" class="y-xicon">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold">Subscriptions</p>
                                        <p>Create a mobile-friendly online store in under an hour.</p>
                                        <p>
                                            $ 16.34 / month
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-4  ">
                                <div class="d-flex bg-light rounded py-4 px-3 h-100 ">
                                    <div class="icon rounded">
                                    </div>
                                    <div>
                                        <p class="font-weight-bold">Marketplace</p>
                                        <p>Create a mobile-friendly online store in under an hour.</p>
                                        <p>
                                            $ 16.34 / month
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </b-col>
            <b-col cols="1"></b-col>
        </b-row>

    </div>
</template>

<script>
    import router from "../../../app/router/router";

    export default {
        name: "Apps",
        data(){
            return{
                activeClass:'active',
                currentPage: this.$route.fullPath,
                title: this.titleParser(this.$route.fullPath),
                name: JSON.parse(localStorage.getItem("business")).name,
                location: localStorage.getItem("location_name"),
                loyalty:JSON.parse(localStorage.getItem('business')).active_products[1].Loyalty,
                subscriptions:JSON.parse(localStorage.getItem('business')).active_products[2].Subscriptions,
                checkoutPath: "/home/checkout"
            }
        },
        mounted() {
            if(localStorage.getItem("token") == null){
                router.push("/")
            }

            if(!this.loyalty){
                this.checkoutPath = "/library"
            }

            if(!this.subscriptions){
                this.checkoutPath = "/library"
            }
        },
        methods:{
            closeSideMenu(){
                this.$refs.mySidebar.hide();
            },

            loadPage(route){
                router.push(route)
            },
            titleParser(route){
                var name = route.split("/")[2]
                return this.capitalizeFirstLetter(name)
            },
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
            logout(){
                localStorage.clear()
                router.push("/")
            }
        },
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";
    @import "../../../commons/css/menu.css";
    @import "../../../commons/css/utils.css";

    .y-xicon{
        width: 32px;
        height: 32px;
    }

    .y-micon{
        width: 20px;
        height: 20px;
    }

    .header{
        margin: 0;
        padding: 0;
        width: 100%;
        z-index: 20;
        position: fixed;
        box-shadow: 0px 5px 3px #dedede;
        background: white;
    }

    .content{
        margin-top: 60px;
    }

    @media only screen and (max-width: 380px) {

    }

    @media only screen and (max-width: 320px) {

    }

</style>
