import axios from "axios";
import configs from "../../../app/configurations/configs";

const IP = configs.SERVER_IP;

const state = {
  response:{},
  error:{}
};

const getters = {
  getLoginResponse: (state) => state.response,
  getLoginErrorResponse: (state) => state.error,
};

const actions = {
  async accountLogin({ commit }, data) {
    await axios
      .post(IP + "/login", data)
      .then((response) => {
        console.log(JSON.stringify(response.data.data))
        var data = response.data.data
        localStorage.setItem("token", data.access_token)
        localStorage.setItem("business", JSON.stringify(data.business))
        localStorage.setItem("business_id", data.business.id)
        localStorage.setItem("user_id", data.user.id)
        commit("setLoginSuccessResponse", response);
      })
      .catch((error) => {
        commit("setLoginErrorResponse", error.response);
      });
  },
};

const mutations = {
  setLoginSuccessResponse: (state, response) => {
    state.response = response.data;
  },
  setLoginErrorResponse: (state, response) => {
    state.error = response.data;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
