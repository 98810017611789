<template>
    <div class="balance">
        <section class="h-100 w-100 d-flex flex-column ">
            <b-row>
                <b-col class="header">
                </b-col>
            </b-row>
            <div class="d-flex justify-content-md-center align-items-center flex-column flex-grow-1 options">
                <p class="text-center bal">UGX {{Number(cashier.balance).toLocaleString()}} Balance</p>
                <p class="text-center mb-5 ">Out of UGX {{Number(cashier.total).toLocaleString()}}</p>

                <b-col col md="8" lg="7" class="mx-auto">

                    <p class="text-center mb-5">How do you want to receive your receipt?</p>

                    <div class="list-group list-group-flush">
                        <b-row style="background: #dedede; height: 1px; width: 100%; margin: 0; padding: 0;"></b-row>
                        <b-row class="y-list-item" @click="print">
                            <b-col cols="11" class="text">
                                <div class="align-items-center">
                                    Print Receipt
                                </div>
                            </b-col>
                            <b-col cols="1">
                                <div class="right-figures">
                                    <img src="../../../assets/ic_next.png" class="y-icon" alt="back">
                                </div>
                            </b-col>
                        </b-row>
                        <b-row class="y-list-item" @click="skip">
                            <b-col cols="11" class="text">
                                <div class="align-items-center">
                                    Skip
                                </div>
                            </b-col>
                            <b-col cols="1">
                                <div class="right-figures">
                                    <img src="../../../assets/ic_next.png" class="y-icon" alt="back">
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </div>
        </section>
    </div>
</template>

<script>
    import router from "../../../app/router/router";
    export default {
        name: "CustomerBalance",
        data(){
            return{
                cashier : JSON.parse(localStorage.getItem("cashier"))
            }
        },
        mounted() {

        },
        methods:{
            print(){
                router.push("/cashier/receipt")
            },
            skip(){
                router.push("/cashier/points")
            },
            back(){
                window.history.back()
            },
        },computed: {

        },
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";

    .header{
        padding: 15px;
    }

    .options{
        margin-top: 80px;
    }

    .y-icon{
        padding: 5px;
        width: 35px;
        margin: 10px;
    }

    .y-icon-header{
        padding: 5px;
        width: 40px;
        margin-top: 10px;
        margin-left: 15px;
    }

    .y-list-item{
        width: 100%;
        font-weight: bold;
        border-bottom: solid 1px #dedede;
        margin: 0px 0px 1px 1px;
        padding: 10px;
    }

    .y-list-item:hover{
        background: rgba(241, 241, 241, 0.55);
    }

    .right-figures{
        margin-top: 10px;
    }

    .text{
        margin: auto;
        padding: 10px;
    }

    .bal{
        font-size: 26px;
        font-weight: bold;
    }
</style>