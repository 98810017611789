import axios from "axios";
import configs from "../../../../app/configurations/configs";

const IP = configs.SERVER_IP;

const state = {
  transactions:[],
  meta:{},
  response:{},
  error:{}
};

const getters = {
  getTransactions: (state) => state.transactions,
  getTransactionsMeta: (state) => state.meta,
  getRefundSuccess: (state) => state.response,
  getTransactionErrorResponse: (state) => state.error,
};

const actions = {
  async fetchTransactions({ commit }) {
    await axios
      .get(IP + "/transactions/business/"+localStorage.getItem("business_id"))
      .then((response) => {
        commit("setTransactionsResponse", response.data);
        commit("setTransactionsMetaResponse", response.data);
      })
      .catch((error) => {
        commit("setErrorResponse", error.response);
      });
  },
  async fetchTransactionsPaged({ commit }, data) {
    await axios
        .get(IP + "/transactions/business/"+localStorage.getItem("business_id")+"?page="+data.page)
        .then((response) => {
          commit("setTransactionsResponse", response.data);
          commit("setTransactionsMetaResponse", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
  async refundTransaction({ commit }, data) {
    const params = new URLSearchParams();
    params.append('refund_reason', data.reason);
    await axios
        .put(IP + "/transactions/"+data.id+"/refund", params)
        .then((response) => {
          commit("setRefundResponse", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
};

const mutations = {
  setTransactionsResponse: (state, response) => {
    state.transactions = response.data;
  },
  setTransactionsMetaResponse: (state, response) => {
    state.meta = response.paginator;
  },
  setRefundResponse: (state, response) => {
    state.response = response.data;
  },
  setErrorResponse: (state, response) => {
    state.error = response.data;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
