<template>
    <div class="cart">
        <b-col cols="12">
            <b-row @click="showDetails(idx)" class="checkout-item" v-for="(item, idx) in getCart" :key="idx">
                <b-col cols="12">
                    <b-row class="item-content">
                        <b-col cols="9">
                            <span class="font-weight-bold">{{item.item.name}}</span><br/>
                            <small>{{item.item.category.name}}</small><br/>
                            <small v-for="(discount,idx) in item.discounts" :key="idx">{{discount.discount_name}}</small>
                        </b-col>

                        <b-col cols="3">
                            <b-row style="text-align: center;">
                                <img v-if="item.subscription_type != null" src="../../assets/ic_active_user.png" class="flag">
                                <p class="font-weight-bold amount" v-if="item.discounts.length > 0">UGX {{Number(item.item.discount_price).toLocaleString()}}</p>
                                <p class="font-weight-bold amount" v-else>UGX {{Number(item.item.price).toLocaleString()}}</p>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="1">

                        </b-col>
                        <b-col cols="10" style="background: #dedede; width: 80%; height: 1px; horiz-align: center;">

                        </b-col>
                        <b-col cols="1">

                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

            <div @click="complete()" class="total-button text">
                Total UGX {{Number(total).toLocaleString()}}
            </div>
        </b-col>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import router from "../../app/router/router"

    export default {
        name: "Cart",
        data(){
            return{
                total:0
            }
        },
        mounted() {
            this.fetchCart()
            this.$root.$on("refresh-cart", this.refresh)
        },
        methods:{
            ...mapActions(["fetchCart"]),
            complete(){
                router.push("/cashier")
            },
            refresh(){
                this.fetchCart()
            },
            showDetails(idx){
                router.push("/cart/item/"+idx)
            }
        },
        computed:{
            ...mapGetters(["getCart"])
        },
        watch:{
            getCart(cart){
                this.total = 0
                var i = 0
                try{
                    for(i=0; i<=cart.length; i++){
                        if(cart[i].discounts.length > 0){
                            this.total += parseInt(cart[i].item.discount_price) * cart[i].qty
                        }else {
                            this.total += parseInt(cart[i].item.price) * cart[i].qty
                        }
                    }
                }catch (e) {
                    console.log(JSON.stringify(e))
                }
            }
        }
    }
</script>

<style scoped>
    @import "../../commons/css/main.css";

    .cart{
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .checkout-item{
        margin: 0;
        padding: 0;
    }

    .checkout-item:hover{
        background: #d3d3d3;
    }

    .text{
        font-size: 22px;
    }

    .total-button{
        position: fixed;
        bottom: 0;
        width: 50%;
        background: #dc3545;
        padding: 20px;
        color: white;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .total-button:hover{
        background: #9c2631;
    }

    .flag{
        margin: 2px;
        width: 25px;
        height: 25px;
    }

    .amount{
        margin-top: 5px;
        margin-left: 5px;
    }

    .item-content{
        margin: 0;
        padding: 20px 10px;
    }
</style>