import axios from "axios";
import configs from "../../../../app/configurations/configs";

const IP = configs.SERVER_IP;

const state = {
  response:{},
  error:{},
  r_response:{},
  r_error:{},
  rewards:[]
};

const getters = {
  getRewards: (state) => state.rewards,
  getRedeemCustomerSuccessResponse: (state) => state.response,
  getRedeemCustomerErrorResponse: (state) => state.error,
  getRedeemSuccessResponse: (state) => state.r_response,
  getRedeemErrorResponse: (state) => state.r_error,
  getOTPSuccessResponse: (state) => state.response,
  getOTPErrorResponse: (state) => state.error,

};

const actions = {
  async fetchRedeemCustomer({ commit }, phone) {
    await axios
      .get(IP + "/customers/"+phone+"?business_id="+localStorage.getItem("business_id"))
      .then((response) => {
        console.log(JSON.stringify(response.data))
        commit("setSuccessResponse", response.data);
      })
      .catch((error) => {
        commit("setErrorResponse", error.response);
      });
  },

  async fetchRewards({ commit }) {
    await axios
        .get(IP + "/rewards/location/"+localStorage.getItem("location_id"))
        .then((response) => {
          console.log(JSON.stringify(response.data))
          commit("setRewardsResponse", response.data);
        })
        .catch((error) => {
          commit("setRedeemErrorResponse", error.response);
        });
  },

  async verifyRedeem({ commit }, data) {
    const params = new URLSearchParams();
    params.append('business_id', localStorage.getItem("business_id"));
    params.append('customer_id', data.customer_id);
    params.append('otp', data.otp);
    await axios
        .post(IP + "/redeems/verify", params)
        .then((response) => {
          console.log(JSON.stringify(response.data))
          commit("setOTPResponse", response.data);
        })
        .catch((error) => {
          commit("setOTPErrorResponse", error.response);
        });
  },

  async redeemReward({ commit }, data) {
    const params = new URLSearchParams();
    params.append('business_id', localStorage.getItem("business_id"));
    params.append('location_id', localStorage.getItem("location_id"));
    params.append('customer_id', data.customer_id);
    params.append('reward_id', data.reward_id);
    params.append('item_id', data.item_id);
    params.append('transaction_type', "Redeem");
    params.append('points', data.points);

    await axios
        .post(IP + "/redeems", params)
        .then((response) => {
          console.log(JSON.stringify(response.data))
          commit("setRedeemResponse", response.data);
        })
        .catch((error) => {
          commit("setRedeemErrorResponse", error.response);
        });
  },

  async nativeRedeem({ commit }, data) {
    const params = new URLSearchParams();
    params.append('business_id', localStorage.getItem("business_id"));
    params.append('location_id', localStorage.getItem("location_id"));
    params.append('customer_id', data.customer_id);
    params.append('item_id', data.item_id);
    params.append('reward_id', data.reward_id);
    params.append('receipt_no', data.receipt_no);
    params.append('points', data.points);

    await axios
        .post(IP + "/redeems/nativeredeem", params)
        .then((response) => {
          console.log(JSON.stringify(response.data))
          commit("setRedeemResponse", response.data);
        })
        .catch((error) => {
          commit("setRedeemErrorResponse", error.response);
        });
  },
};

const mutations = {
  setSuccessResponse: (state, response) => {
    state.response = response.data;
  },
  setErrorResponse: (state, response) => {
    state.error = response.data;
  },
  setOTPResponse: (state, response) => {
    state.response = response.data;
  },
  setOTPErrorResponse: (state, response) => {
    state.error = response.data;
  },
  setRewardsResponse: (state, response) => {
    state.rewards = response.data;
  },
  setRedeemResponse: (state, response) => {
    state.r_response = response.data;
  },
  setRedeemErrorResponse: (state, response) => {
    state.r_error = response.data;
  },

};

export default {
  state,
  getters,
  actions,
  mutations,
};
