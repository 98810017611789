<template>
    <div class="categories">
        <b-row>
            <b-col class="header">
                <img src="../../../assets/ic_navigate_back.png" class="y-icon-header" alt="back" @click="back">
            </b-col>
        </b-row>

        <b-row class="heading">
            <b-col cols="12">
                <p class="font-weight-bold h3 text-center">{{title}}</p>
            </b-col>
        </b-row>
        <b-row class="library">
            <b-col cols="3"></b-col>
            <b-col cols="6" style="margin: 0; padding: 0;">
                <div class="list-group list-group-flush" v-if="showCategories">
                    <b-overlay :show="loading" sm="rounded">
                        <b-row class="y-list-item" v-for="(category, idx) in getCategories" :key="idx" @click="selectCategory(category.id)">
                            <b-col cols="11" style="margin: 0; padding: 0;">
                                <div class="d-flex align-items-center">
                                    <div :class="[(idx % 2) ? 'item-initials-dark':'item-initials']">
                                        {{getInitial(category.name)}}
                                    </div>
                                    <div>
                                        <p class="mb-0">{{category.name}}</p>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="1">
                                <div class="right-figures">
                                    <img src="../../../assets/ic_next.png" class="y-icon" alt="back">
                                </div>
                            </b-col>
                        </b-row>
                    </b-overlay>
                </div>

                <div class="list-group list-group-flush" v-if="showCategoryItems">
                    <b-overlay :show="loading" sm="rounded">
                        <b-row class="y-list-item" v-for="(citem, idx) in getCategoryItems" :key="idx" @click="selectItem(citem)">
                            <b-col cols="11" style="margin: 0; padding: 0;">
                                <div class="d-flex align-items-center">
                                    <div class="item-initials-grey">
                                        {{getInitial(citem.name)}}
                                    </div>
                                    <div>
                                        <p class="mb-0">{{citem.name}}</p>
                                        <p class="desc">{{citem.description}}</p>
                                    </div>
                                </div>
                            </b-col>
                            <b-col cols="1">
                                <div class="right-figures">
                                    <img src="../../../assets/ic_next.png" class="y-icon" alt="back">
                                </div>
                            </b-col>
                        </b-row>
                    </b-overlay>

                </div>
            </b-col>
            <b-col cols="3"></b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import router from "../../../app/router/router";

    export default {
        name: "RedeemLibrary",
        data(){
            return{
                showCategories:true,
                showCategoryItems:false,
                redeem: null,
                loading: false,
                title: ""
            }
        },
        mounted() {
          this.title = "LIBRARY"
          this.redeem = JSON.parse(localStorage.getItem("redeem"))
          this.fetchCategories()
        },
        methods:{
            ...mapActions(["fetchCategories", "fetchCategoryItems", "redeemReward"]),
            getInitial(name){
                return name.charAt(0).toUpperCase()+name.charAt(1).toLowerCase()
            },
            selectCategory(id){
                this.fetchCategoryItems(id);
                this.loading = true
                this.title = "MEMBERSHIP PACKAGES"
                this.showCategoryItems = true
                this.showCategories = false
            },
            selectItem(item){
                this.redeem.item_id = item.id
                this.redeem.item = item
                localStorage.setItem("redeem", JSON.stringify(this.redeem))
                this.redeemReward(this.redeem)
            },
            back(){
                if(this.showCategoryItems){
                    this.loading = true
                    this.showCategoryItems = false
                    this.showCategories = true
                    this.fetchCategories()
                    this.title = "LIBRARY"
                }else{
                    window.history.back()
                }
            }
        },
        computed:{
            ...mapGetters([
                "getCategories",
                "getCategoryItems",
                "getLibraryErrorResponse",
                "getRedeemSuccessResponse",
                "getRedeemErrorResponse",
            ]),
        },
        watch:{
            getCategoryItems(data){
                this.loading= false
                if(data.length > 0){
                    this.showCategoryItems = true
                    this.showCategories = false
                }
            },
            getCategories(){
                this.loading= false
            },
            getRedeemSuccessResponse(data){
                this.redeem.date = data.created_at
                localStorage.setItem("redeem", JSON.stringify(this.redeem))

              // if(localStorage.getItem("business_id") === "d6735c0e-9b41-4333-b807-47cfa8000166"){
              //   router.push("/complete/redeem-otp")
              // }else{
              //   router.push("/redeem-complete")
              // }

              router.push("/complete/redeem-otp")
            },
            getRedeemErrorResponse(data){
                alert(data.message)
            }
        }
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";

    .categories{
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .heading{
        margin-top: 60px;
    }

    .library{
        margin-top: 70px;
      margin-bottom: 60px;
    }
    .y-icon{
        padding: 5px;
        width: 35px;
        margin: 10px;
    }

    .list-group{
        margin-top: 5px;
    }

    .y-list-item{
        width: 100%;
        font-weight: bold;
        border-bottom: solid 1px #a0a0a0;
        margin: 0px 0px 1px 1px;
        padding: 0;
    }

    .y-list-item:hover{
        background: rgba(241, 241, 241, 0.55);
    }

    .item-initials{
        width: 80px;
        height: 80px;
        color: white;
        background: #dc3545;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        flex-shrink: 0;
        margin-right: 15px;
    }

    .item-initials-dark{
        width: 80px;
        height: 80px;
        color: white;
        background: #9c2631;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        flex-shrink: 0;
        margin-right: 15px;
    }

    .item-initials-grey{
        width: 75px;
        height: 80px;
        color: white;
        background: #a0a0a0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: bold;
        flex-shrink: 0;
        margin-right: 15px;
    }

    .right-figures{
        margin-top: 10px;
    }

    .back{
        width: 100%;
        color: white;
        background: #dc3545;
        padding: 10px;
        border-radius: 6px;
        margin-left: 2px;
        margin-top: 10px;
    }

    .desc{
        font-size: 12px;
        color: #3D454C;
        font-weight: normal;
    }

    .y-icon-header{
        padding: 5px;
        width: 40px;
        margin-top: 10px;
        margin-left: 15px;
    }
</style>