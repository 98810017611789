<template>
    <div class="txn-points">
        <b-row>
            <b-col class="header">
                <img src="../../../assets/ic_navigate_back.png" class="y-icon-header" alt="back" @click="back">
            </b-col>
        </b-row>

        <div class="d-flex justify-content-md-center align-items-center flex-column flex-grow-1 options">
            <span class="text-center points">{{cashier.points}}</span>
            <span class="text-center text">Points</span>
        </div>

        <div class="d-flex justify-content-md-center align-items-center flex-column flex-grow-1 options">
            <p class="text-center text2">Have been added to your account</p>
        </div>

        <b-row class="mx-auto py-4 py-md-0 fill2">
            <b-col cols="2"></b-col>
            <b-col cols="8">
                <button class="btn btn-danger button-text" @click="next">Done</button>
            </b-col>
            <b-col cols="2"></b-col>
        </b-row>
    </div>
</template>

<script>
    import router from "../../../app/router/router";

    export default {
        name: "TransactionPoints",
        data(){
            return{
                amount:null,
                cashier: JSON.parse(localStorage.getItem("cashier"))
            }
        },
        mounted() {

        },
        methods:{
            // back(){
            //     window.history.back()
            // },
            next(){
                router.push("/cashier/complete")
            },
        },computed: {

        },
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";

    .header{
        padding: 15px;
    }

    .options{
        margin-top: 110px;
    }

    .y-icon-header{
        padding: 5px;
        width: 40px;
        margin-top: 10px;
        margin-left: 15px;
    }

    .points{
        font-weight: bold;
        color: #A6C34C;
        font-size: 85px;
    }

    .text{
        font-weight: bold;
        color: #A6C34C;
        font-size: 50px;
        line-height: 5px;
    }

    .text2{
        font-size: 22px;
    }

    .button-text{
        font-weight: bold;
        padding: 20px;
        width: 100%;
        font-size: 18px;
        margin-top: 35px;
    }
</style>