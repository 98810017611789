<template>
    <div>
        <b-row class="header">
          <b-col cols="8" sm="10" md="10" lg="11">
            <a class="navbar-brand">
              <img @click="cancel" src="../../../assets/ic_cancel.png" class="y-icon-header">
            </a>
          </b-col>
          <b-col cols="4" sm="2" md="2" lg="1">
            <button @click="onSave" class="btn btn-danger py-md-4 px-md-5 m-md-0 py-3 rounded-0">Save</button>
          </b-col>
        </b-row>

        <b-row class="content justify-content-center align-items-center flex-column">
            <b-col cols="1" sm="1" md="2" lg="4"></b-col>
            <b-col cols="10" sm="10" md="8" lg="4" >
                <b-overlay :show="loading" rounded="sm">
                    <b-row >
                        <b-col>
                            <h3 class="space text-center font-weight-bold h3">Enter Customer Details</h3>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" class="field space-f">
                            <b-row>
                                <span class="label">First Name *</span>
                            </b-row>
                            <b-row>
                                <input v-model="form.fname" type="text" class="input">
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" class="field space-f">
                            <b-row>
                                <span class="label">Last Name *</span>
                            </b-row>
                            <b-row>
                                <input v-model="form.lname" type="text" class="input">
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row v-if="settings[1].email">
                        <b-col cols="12" class="field space-f">
                            <b-row>
                                <span class="label">Email *</span>
                            </b-row>
                            <b-row>
                                <input v-model="form.email" type="email" class="input">
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row v-if="settings[4].date_of_birth">
                        <b-col cols="12" class="field space-f">
                            <b-row>
                                <span class="label">Date of Birth *</span>
                            </b-row>
                            <b-row>
                                <input v-model="form.dob" type="date" class="input">
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="12" class="field space-f">
                            <b-row>
                                <span class="label">Gender *</span>
                            </b-row>
                            <b-row>
                                <select v-model="form.gender" type="text" class="input">
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                </select>
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row >
                        <b-col cols="12" class="field space-f">
                            <b-row>
                                <span class="label">Address</span>
                            </b-row>
                            <b-row>
                                <input v-model="form.address" type="text" class="input">
                            </b-row>
                        </b-col>
                    </b-row>
                </b-overlay>
            </b-col>
            <b-col cols="1" sm="1" md="2" lg="4"></b-col>
        </b-row>

    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import router from "../../../app/router/router";
    export default {
        name: "CustomerSignup",
        data(){
            return{
                settings: JSON.parse(localStorage.getItem("business")).settings,
                form:{
                    fname:null,
                    lname:null,
                    email:null,
                    dob:null,
                    gender:null,
                    phone:null,
                    address:null
                },
                loading: false
            }
        },
        mounted() {
            this.form.phone = window.location.href.split('/')[4]
        },
        methods:{
            ...mapActions(["createCustomer", "checkout"]),
            onSave(){
                this.loading = true
                this.createCustomer(this.form)
            },
            cancel(){
                window.history.back();
            },
        },
        computed: {
            ...mapGetters([
                "getCustomerSuccessResponse",
                "getCustomerErrorResponse",
                "getCheckoutSuccessResponse",
                "getCheckoutErrorResponse",
            ]),
        },
        watch:{
            getCustomerSuccessResponse(){
                this.loading = true
                this.checkout(this.form.phone)
            },
            getCustomerErrorResponse(data){
                alert(data.error)
            },
            getCheckoutSuccessResponse(data){
                this.loading = false
                localStorage.setItem("customer", JSON.stringify(data))
                router.push("/customer")
            },
            getCheckoutErrorResponse(data){
                alert(data.error)
                router.push("/customer-signup/"+this.phone)
            }
        }
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";

    .header{
        position: fixed;
        top: 0;
        left: 0;
        padding: 0;
        margin: 0;
        width: 100%;
        z-index: 40;
        box-shadow: 0px 5px 3px #dedede;
        background: white;
    }

    .content{
        margin-top: 6%;
    }

    .y-icon-header{
        padding: 5px;
        width: 38px;
        height: 38px;
        margin: 16px 10px 10px 10px
    }

    .y-icon-header:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .input{
        width: 100%;
        border: 0 none;
    }

    .input:focus{
        border: 0 none;
    }

    .input:active{
        border: 0 none;
    }

    input:focus, textarea:focus, select:focus{
        outline: none;
    }

    .label{
        color: #a0a0a0;
    }

    .field{
        border: solid 1px #777777;
        border-radius: 6px;
        width: 100%;
        padding: 10px 20px;
    }

    .space{
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .space-f{
      margin-top: 20px;
      margin-bottom: 20px;
    }

    .right{
        float: right;
    }

    @media only screen and (max-width: 480px){
      .header{
        /*height: 65px;*/
      }

      .content{
        margin-top: 15%;
      }

      .y-icon-header{
        padding: 2px;
        width: 22px;
        height: 22px;
        margin: 6px 3px 3px 3px
      }

      .y-icon-header:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
      }

      .h3{
        font-size: 16px;
      }

      .space-f{
        margin-top: 15px;
        margin-bottom: 15px;
      }

      .space{
        margin-top: 45px;
      }

      .field{
        padding: 8px 16px;
      }

      .input{
        margin-left: 10px;
      }

      .label{
        margin-left: 10px;
        font-size: 12px;
      }

      .btn{
        font-size: 12px;
      }
    }

    @media only screen and (max-width: 380px){

    }

    @media only screen and (max-width: 360px){
      .btn{
        width: 100%;
        padding: 0;
        margin: 0;
      }
    }

    @media only screen and (max-width: 320px){

    }

</style>