<template>
    <div class="receipts">
        <b-row class="header">
            <a class="navbar-brand ml-3">
                <img @click="cancel()" src="../../../assets/ic_cancel.png" class="y-icon-header">
            </a>
            <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">

            </div>
            <button @click="done()" class="btn btn-danger py-md-4 px-md-5 m-md-0 px-4 m-2 rounded-0">Done</button>
        </b-row>

        <section class="h-100 w-100 d-flex justify-content-center align-items-center flex-column mb-5 content">
            <b-col col md="8" lg="8" class="mx-auto mt-5 mt-md-0">
                <b-row class="pb-5 less">
                    <b-col col md="7" lg="7" class="order-md-0 order-1" id="printable">
                        <div class="card">
                            <div class="card-img-container justify-content-center align-items-center d-flex flex-column">
                                <div class="border rounded border-white p-4" style="border-width: 2px  !important;">

                                </div>
                                <p class="text-center text-white mt-3 h3">{{business_name}}</p>
                            </div>
                          <div class="card-body">
                            <div class="my-5  d-flex justify-content-md-center">
                              <sup class="small">Points</sup>
                              <h5 class="card-title pts font-weight-bold  ">
                                {{redeem.points}}
                              </h5>
                            </div>

                            <p class="card-text small text-right my-3">{{getDateString()}}</p>
                            <div class="d-flex justify-content-between">
                              <span>Receipt No.</span>
                              <span class="font-weight-bold">#00000000</span>
                            </div>

                            <div class="receipt-section  my-3" v-if="redeem.item != null">
                              <div class="d-flex justify-content-between small">
                                <p class="font-weight-bold item-text">{{redeem.item.name}}</p>
                                <p class="item-text amount">UGX {{redeem.item.price}}</p>
                              </div>
                            </div>

                            <div class="receipt-section  my-3 ">
                              <div class="d-flex justify-content-between h4">
                                <p class="font-weight-bold">Total</p>
                                <p class="font-weight-bold">UGX 0</p>
                              </div>
                            </div>

                            <div class="text-center h5">
                              <p class="font-weight-bold">{{business_name}}</p>
                              <p>{{location_name}}</p>
                            </div>

                            <div class="text-center mt-5 small">
                              <p>Powered by 2020 Yamzit, Ltd. <br> All rights reserved.</p>
                            </div>
                          </div>
                        </div>
                    </b-col>

                    <b-col col md="5" lg="5" class="position-relative order-0 order-md-1">
                        <div class="fix-me-there my-5 my-md-0 position">
                            <h2 class="h5 mt-3 d-flex justify-content-center justify-content-md-left">No of receipts</h2>
                            <b-row class="input-group mb-3">
                                <b-col cols="8" class="less">
                                    <div class="input-group-prepend">
                                        <div class="btn-group-vertical" role="group">
                                            <button type="button" class="btn btn-secondary py-3 px-5" @click="copies++">
                                                <img src="../../../assets/up.svg"/>
                                            </button>
                                            <button type="button" class="btn btn-secondary py-3 px-5 m-0 border-top-0" @click="copies--">
                                                <img src="../../../assets/down.svg"/>
                                            </button>
                                        </div>
                                    </div>
                                </b-col>
                                <b-col cols="4" class="less">
                                    <input type="text" class="form-control text-center copies" v-model="copies">
                                </b-col>
                            </b-row>

                            <b-row class="input-group mb-3">
                                <b-col cols="12" class="button less">
                                    <a @click="print()" class="btn btn-danger d-block text-center py-3">Print</a >
                                </b-col>
                            </b-row>
                        </div>
                    </b-col>
                </b-row>
            </b-col>
        </section>
    </div>
</template>

<script>
    import router from "../../../app/router/router";
    import moment from "moment";
    export default {
        name: "RedeemReceipt",
        data(){
            return{
                redeem: null,
                business_name: null,
                location_name:null,
                copies:1,
                months:["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
            }
        },
        mounted() {
          this.redeem = JSON.parse(localStorage.getItem("redeem"))
          this.business_name = JSON.parse(localStorage.getItem("business")).name
          this.location_name = localStorage.getItem("location_name")
        },
        methods:{
            done(){
              router.push("/home/redeem")
              localStorage.removeItem("redeem")
              localStorage.removeItem("redeem_customer")
            },
            cancel(){
                router.push("/home/redeem")
                localStorage.removeItem("redeem")
            },
            print(){
                window.print()
            },
            getDateString(){
                return moment().format("DD-MM-YYYY")
            },
            getTime(date){
                var sections = date.split("T")
                var sections2 = sections[1].split(".")
                var sections3 = sections[1].split(":")
                var total = parseInt(sections2[0].replaceAll(":"))
                if(total > 120000){
                    return sections3[0]+":"+sections3[1] + " pm"
                }else{
                    return sections3[0]+":"+sections3[1] + " am"
                }
            },
        },computed: {
            getCopies(){
                return this.copies
            }
        },
        watch:{
            getCopies(num){
                if(num < 1){
                    this.copies = 1
                }
            }
        }
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";

    .header{
        padding: 0;
        margin: 0;
        width: 100%;
        height: 85px;
        box-shadow: 0px 5px 3px #dedede;
    }

    .content{
        margin-top: 20px;
    }

    .y-icon-header{
        padding: 5px;
        width: 38px;
        height: 38px;
        margin: 16px 10px 10px 10px
    }

    .y-icon-header:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .copies{
        font-size: 38px;
        font-weight: bold;
        height: 100%;
    }

    .less{
        margin: 0;
        padding: 0;
    }

    .button{
        color: white;
        font-weight: bold;
    }

    .position{
        margin-left: 20px;
    }

    .item-text{
        font-size: 16px;
    }

    .amount{
        font-style: italic;
    }

    .pts{
        font-size: 40px;

    }
</style>