import axios from "axios";
import configs from "../../../app/configurations/configs";
//import qs from "querystring";

const IP = configs.SERVER_IP;

const state = {
  categories:[],
  category_items:[],
  team:[],
  discounts:[],
  response:{},
  response2:{},
  txn_response:{},
  error:{},
  cart:[]
};

const getters = {
    getCategories: (state) => state.categories,
    getCategoryItems: (state) => state.category_items,
    getCart: (state) => state.cart,
    getTeam: (state) => state.team,
    getDiscounts: (state) => state.discounts,
    getTransactionResponse: (state) => state.txn_response,
    getTransactionSuccessResponse: (state) => state.response,
    getLibraryErrorResponse: (state) => state.error,
    getItemActionErrorResponse: (state) => state.error,
};

const actions = {
  async makeTransaction({ commit }, data) {
    const params = new URLSearchParams();
    params.append('customer_id', data.customer_id);
    params.append('business_id', data.business_id);
    params.append('location_id', data.location_id);
    params.append('total_cash',  data.total_cash);
    params.append('payment_type',  data.payment_type);
    params.append('transaction_details',  JSON.stringify(data.transaction_details));
    await axios
      .post(IP + "/transactions", params, {
          headers:{
              "Content-Type":" application/x-www-form-urlencoded"
          }
      })
      .then((response) => {
        commit("setSuccessResponse", response.data);
      })
      .catch((error) => {
        commit("setErrorResponse", error.response);
      });
  },
  async addSubscription({ commit }, data) {
      const params = new URLSearchParams();
      params.append('customer_id', data.customer_id);
      params.append('business_id', data.business_id);
      params.append('location_id', data.location_id);
      params.append('amount',  data.amount);
      params.append('duration',  data.duration);
      params.append('subscription_type',  data.subscription_type);
      params.append('payment_plan',  data.payment_plan);
      await axios
          .post(IP + "/customer-subscriptions", params, {
              headers:{
                  "Content-Type":" application/x-www-form-urlencoded"
              }
          })
          .then((response) => {
             commit("setSuccessSubscriptionResponse", response.data);
          })
          .catch((error) => {
              commit("setErrorResponse", error.response);
          });
  },
  async fetchCategories({ commit }) {
    await axios
        .get(IP + "/category/business/"+localStorage.getItem("business_id"))
        .then((response) => {
          commit("setCategories", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
  async fetchCategoryItems({ commit}, id) {
    await axios
       .get(IP + "/items?category_id="+id+"&location_id="+localStorage.getItem("location_id")+"&business_id="+localStorage.getItem("business_id"))
       .then((response) => {
           commit("setCategoryItems", response.data);
       })
       .catch((error) => {
           commit("setErrorResponse", error.response);
       });
  },
  async fetchTeam({ commit}) {
    await axios
        .get(IP + "/teams/business/"+localStorage.getItem("business_id"))
        .then((response) => {
          commit("setTeam", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
  async fetchDiscounts({ commit}) {
    await axios
        .get(IP + "/business-discounts/business/"+localStorage.getItem("business_id")+"?location="+localStorage.getItem("location_id"))
        .then((response) => {
          commit("setDiscounts", response.data);
        })
        .catch((error) => {
          commit("setErrorResponse", error.response);
        });
  },
  async fetchCart({ commit }) {
      var cart = JSON.parse(localStorage.getItem("cart"))
      commit("setCartItems", cart);
  },
};

const mutations = {
  setCategories: (state, response) => {
    state.categories = response.data;
  },
  setCategoryItems: (state, response) => {
      state.category_items = response.data;
  },
  setCartItems: (state, response) => {
    state.cart = response;
  },
  setTeam: (state, response) => {
    state.team = response.data;
  },
  setDiscounts: (state, response) => {
    state.discounts = response.data;
  },
  setSuccessResponse: (state, response) => {
    state.response = response.data;
  },
  setSuccessSubscriptionResponse: (state, response) => {
      state.response2 = response.data;
  },
  setErrorResponse: (state, response) => {
    state.error = response.error;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
