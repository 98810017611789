import Vue from "vue";
import Vuex from "vuex";
import login from "../../views/auth/vuex-store/login";
import locations from "../../views/location/vuex-store/locations";
import checkout from "../../views/home/checkout/vuex-store/checkout";
import transactions from "../../views/home/transactions/vuex-store/transactions";
import expenses from "../../views/home/expenses/vuex-store/expenses";
import library from "../../views/library/vuex-store/library";
import customer from "../../views/home/checkout/vuex-store/customer";
import redeems from "../../views/home/redeems/vuex-store/redeem";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    login,
    locations,
    checkout,
    customer,
    transactions,
    expenses,
    redeems,
    library,
  }
});
