<template>
    <div>
        <RewardsHeader title=""/>

        <b-row style="margin-top: 10px;">
            <b-col cols="6"></b-col>
            <b-col cols="1">
                <h3 class="h3 mx-auto bold title">REWARDS</h3>
            </b-col>
            <b-col cols="3">
                <img src="../../../assets/customer_rewards.png"/>
            </b-col>
            <b-col cols="2"></b-col>
        </b-row>
        <!--content-->
        <b-row style="margin-top: 40px;">
            <b-col cols="2"></b-col>
            <b-col cols="8">
                <b-row class="y-list-item" v-for="(reward, idx) in getRewards" :key="idx" @click="onSelect(reward.id, reward.points, reward.name)">
                    <img src="../../../assets/non_active_user.png" class="y-icon">
                    <b-col cols="8">
                        <b-row>
                            <b-col cols="2" class="points">
                                <b-row>
                                    <div class="less">
                                        <p :class="(customer.details.current_points >= reward.points)?'pts-active':'pts'">{{reward.points}}</p>
                                    </div>
                                </b-row>
                                <b-row>
                                    <div class="less">
                                        <p :class="(customer.details.current_points >= reward.points)?'text-pts-active':'text-pts'">Points</p>
                                    </div>
                                </b-row>
                            </b-col>
                            <b-col cols="10">
                                <b-row>
                                    <p class="name">{{reward.name}}</p>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-col>
            <b-col cols="2"></b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";
    import RewardsHeader from "../../../commons/components/RewardsHeader";
    import router from "../../../app/router/router"

    export default {
        name: "Rewards",
        components: {RewardsHeader},
        data(){
            return{
                customer:JSON.parse(localStorage.getItem("redeem_customer"))
            }
        },
        mounted() {
            this.fetchRewards()
        },
        methods:{
            ...mapActions(["fetchRewards", "redeemReward"]),
            onSelect(id, points, name){
                if(this.customer.details.current_points >= points){
                    let data = {
                        customer_id: this.customer.id,
                        points: points,
                        reward_id: id,
                        name:name
                    }
                    localStorage.setItem("redeem", JSON.stringify(data))
                    router.push("/redeem-item")
                }
            },
            capitalizeFirstLetter(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            },
        },
        computed: {
            ...mapGetters([
                "getRewards",
                "getRedeemSuccessResponse",
                "getRedeemErrorResponse",
            ]),
        },
        watch:{
            getRedeemSuccessResponse(){
                window.history.back();
            },
            getRedeemErrorResponse(data){
                alert(data.message)
            }
        }
    }
</script>

<style scoped>
    @import "../../../commons/css/main.css";

    .bold{
        font-weight: bold;
    }

    .title{
        margin-top: 80px;
    }

    .y-icon{
        margin: 10px;
        height: 90px;
        width: 90px;
    }

    .y-list-item{
        padding: 5px;
        text-align: center;
        border-bottom: solid 1px #3D454C;
    }

    .y-list-item:hover{
        background: #f1f1f1;
    }

    .name{
        margin-top: 40px;
    }

    .pts{
        color: #a0a0a0;
        font-weight: bold;
        font-size: 40px;
        text-align: center;
        margin: 0;
        padding: 0;
        line-height: 40px;
    }

    .text-pts{
        color: #a0a0a0;
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        margin: 0;
        padding: 0;
    }

    .pts-active{
        color: #99d40d;
        font-weight: bold;
        font-size: 40px;
        text-align: center;
        margin: 0;
        padding: 0;
        line-height: 40px;
    }

    .text-pts-active{
        color: #99d40d;
        font-weight: bold;
        font-size: 20px;
        text-align: center;
        margin: 0;
        padding: 0;
    }

    .less{
        width: 100%;
        margin: 0;
        padding: 0;
        text-align: center;
    }

    .points{
        margin-top: 20px;
    }
</style>