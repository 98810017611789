<template>
    <div class="cart-item">

        <b-row class="header">
            <a class="navbar-brand ml-3">
                <img @click="cancel()" src="../../assets/ic_cancel.png" class="y-icon-header">
            </a>
            <div class="flex-grow-1 d-md-flex align-items-center justify-content-center d-none">
                <h3 class="h3 text-center title">Team</h3>
            </div>

        </b-row>

        <b-row class="content h-100 w-100 d-flex justify-content-center align-items-center flex-column mb-5">
            <b-col md="8" lg="8" class="mx-auto">
                <b-row>
                    <b-col cols="12">
                        <b-row class="header-members">
                            <b-col cols="11">
                                <span class="font-weight-bold">Name</span>
                            </b-col>
                            <b-col cols="1">
                                <span class="font-weight-bold">Selected</span>
                            </b-col>
                        </b-row>
                        <b-overlay :show="loading" rounded="sm">
                            <b-row class="member" v-for="(member, idx) in getTeam" :key="idx" @click="add(member)">
                                <b-col cols="11">
                                    <span class="name">{{member.name}}</span>
                                </b-col>
                                <b-col cols="1">
                                    <img v-if="isInTeam(member.id)" src="../../assets/ic_tick.png" class="y-icon"/>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </b-col>
                </b-row>

            </b-col>
        </b-row>
    </div>
</template>

<script>
    import {mapGetters, mapActions} from "vuex";
    import router from "../../app/router/router";
    export default {
        name: "ItemTeam",
        data(){
            return{
                data:{},
                cart: JSON.parse(localStorage.getItem("cart")),
                idx:null,
                loading:false
            }
        },
        mounted() {
            this.idx = parseInt(window.location.href.split('/')[5])
            this.loading = true
            this.fetchTeam()
        },
        methods:{
            ...mapActions(["fetchTeam"]),
            cancel(){
                window.history.back();
            },
            add(member){
                var added = this.cart[this.idx]
                added.team.push(member)
                this.cart.splice(this.idx, 1)
                var idx = this.cart.push(added)
                localStorage.setItem("cart", JSON.stringify(this.cart))
                this.cart = JSON.parse(localStorage.getItem("cart"))
                router.push("/cart/item/"+(idx-1))
            },
            isInTeam(id){
                let team = this.cart[this.idx].team
                for(let i=0; i<team.length; i++){
                    if(id == team[i].id){
                        return true
                    }
                }
                return false;
            }
        },
        computed:{
            ...mapGetters(["getTeam", "getItemActionErrorResponse"]),
        },
        watch:{
            getTeam(){
                this.loading = false
            },
            getItemActionErrorResponse(data){
                alert(data.error)
            }
        }

    }
</script>

<style scoped>
    @import "../../commons/css/main.css";

    .cart-item{
        width: 100%;
    }

    .header{
        position: fixed;
        top: 0;
        left: 0;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 85px;
        z-index: 40;
        box-shadow: 0px 5px 3px #dedede;
        background: white;
    }

    .content{
        margin-top: 10%;
    }

    .y-icon{
        padding: 5px;
        width: 38px;
        height: 38px;
    }

    .y-icon-header{
        padding: 5px;
        width: 38px;
        height: 38px;
        margin: 16px 10px 10px 10px
    }

    .y-icon-header:hover{
        padding: 6px;
        background: #f3f3f3;
        border-radius: 6px;
    }

    .title{
        font-size: 28px;
        margin-top: 15px;
        font-weight: bold;
        margin-left: 10px;
    }

    .header-members{
        background: #dedede;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    .member{
        padding-top: 15px;
        padding-bottom: 15px;
        border-bottom: solid 1px #dedede;
        font-weight: bold;
    }

    .member:hover{
        background: #f2f2f2;
    }

    .name{
        margin: 0 auto;
    }

</style>